import * as actionTypes from './actionTypes';

export const fetchDashboardData = (token, callback) => { // SAGA
    return {
        type: actionTypes.FETCH_DASHBOARD_DATA,
        token,
        callback
    }
}
export const fetchDashboardDataStart = () => {
    return {
        type: actionTypes.FETCH_DASHBOARD_DATA_START
    }
}
export const fetchDashboardDataSuccess  = data => {
    return {
        type: actionTypes.FETCH_DASHBOARD_DATA_SUCCESS,
        data
    }
}
export const fetchDashboardDataFailed = (error) => {
    return {
        type: actionTypes.FETCH_DASHBOARD_DATA_FAILED,
        error
    }
}