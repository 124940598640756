import { notify } from '../../components/UI/Notifications/Notification/Notification';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    tendersSearchData: {
        search_query: '',
        id_cat: '',
        sort: '',
    },
    
    categories: [],
    industries: [],
    orgs: [],
    ownerships: [],
    types: [],
    
    miscLoading: true,
    miscError: false,
    
    tenders: [],
    tendersPageNumber: 1,
    tendersResultsCount: null,
    tendersLoading: false,
    tendersError: false,

    toggleBookmarkLoading: false,
    toggleBookmarkError: false,

    bookmarkIds: [],
    bookmarkIdsError: false,

    bookmarks: [],
    bookmarksResultsCount: null,
    bookmarksLoading: false,
    bookmarksError: false,

    tenderSearchBookmarks: [],
    tenderSearchBookmarksLoading: false,
    fetchTenderSearchError: false,

    myTenders: [],
    myTendersResultsCount: null,
    myTendersLoading: false,
    myTendersError: false,

    removeTenderLoading: false,
    removeTenderError: false,

};


export const tenderInputChangedHandler = (state, action) => {
    const tendersSearchData = {...state.tendersSearchData}
    const {value, inputName} = action;
    tendersSearchData[inputName] = value;
    return {
        ...state,
        tendersSearchData
    }
}

const fetchTenderMiscStart = (state, action) => {    
    return {
        ...state,
        miscError: false,
        miscLoading: true,
    }
}
const fetchTenderMiscSuccess = (state, action) => {
    return {
        ...state,
        categories: action.misc.categories,
        industries: action.misc.industries,
        orgs: action.misc.orgs,
        ownerships: action.misc.ownerships,
        types: action.misc.types,
        miscError: false,
        miscLoading: false,
    }
}
const fetchTenderMiscFailed = (state, action) => {    
    return {
        ...state,
        miscError: action.error,
        miscLoading: false,
    }
}

const searchTenderStart = (state, action) => {
    return {
        ...state,
        tendersError: false,
        tendersLoading: true,
    };
}
const searchTenderSuccess = (state, action) => {
    const {tenders, tendersSearchData, tendersResultsCount, tendersPageNumber} = action;
    
    return {
        ...state,
        tenders,
        tendersSearchData,
        tendersResultsCount,
        tendersPageNumber,
        tendersError: false,
        tendersLoading: false,
    };
}
const searchTenderFailed = (state, action) => {
    return {
        ...state,
        tendersError: true,
        tendersLoading: false,
    };
}

// remove tender
const removeTenderStart = (state, action) => {    
    return {
        ...state,
        removeTenderLoading: true,
        removeTenderError: false
    }
}
const removeTenderSuccess = (state, action) => {
    const bookmarkIds = state.bookmarkIds.filter(id => id !== action.tenderId);
    return {
        ...state,
        bookmarkIds,
        removeTenderLoading: false,
        removeTenderError: false
    }
}
const removeTenderFailed = (state, action) => {    
    return {
        ...state,
        removeTenderLoading: false,
        removeTenderError: true
    }
}

const fetchMyTendersStart = (state, action) => {    
    return {
        ...state,
        myTendersLoading: true,
        myTendersError: false,
    }
}
const fetchMyTendersSuccess = (state, action) => {
    const {myTenders, myTendersResultsCount} = action;
    return {
        ...state,
        myTenders,
        myTendersResultsCount,
        myTendersLoading: false,
        myTendersError: false
    }
}
const fetchMyTendersFailed = (state, action) => {    
    return {
        ...state,
        myTendersLoading: false,
        myTendersError: true
    }
}


const fetchTenderBookmarksStart = (state, action) => {    
    return {
        ...state,
        bookmarksLoading: true,
        bookmarksError: false,
    }
}
const fetchTenderBookmarksSuccess = (state, action) => {
    const {bookmarks, bookmarksResultsCount} = action;
    return {
        ...state,
        bookmarks,
        bookmarksResultsCount,
        bookmarksLoading: false,
        bookmarksError: false
    }
}
const fetchTenderBookmarksFailed = (state, action) => {    
    return {
        ...state,
        bookmarksLoading: false,
        bookmarksError: true
    }
}

const fetchTenderBookmarkIdsStart = (state, action) => {    
    return {
        ...state,
        bookmarkIdsError: false,
    }
}
const fetchTenderBookmarkIdsSuccess = (state, action) => {
    const {bookmarkIds} = action;
    return {
        ...state,
        bookmarkIds,
        bookmarkIdsError: false,
    }
}
const fetchTenderBookmarkIdsFailed = (state, action) => {    
    return {
        ...state,
        bookmarkIdsError: true,
    }
}



const toggleTenderBookmarkStart = (state, action) => {    
    return {
        ...state,
        toggleBookmarkLoading: true,
        toggleBookmarkError: false
    }
}
const toggleTenderBookmarkSuccess = (state, action) => {
    let bookmarkIds = [...state.bookmarkIds];
    let bookmarks = [...state.bookmarks];

    if(action.responseMessage === 'added' ) {
        bookmarkIds = [...bookmarkIds, action.tenderId];
        notify('Dodato u praćenje', 'Success', action.callback);
    }
    if(action.responseMessage === 'removed' ) {
        bookmarkIds = bookmarkIds.filter(id => id !== action.tenderId);
        bookmarks = bookmarks.filter(bm => bm.id !== action.tenderId);
        notify('Uklonjeno iz praćenja', 'Success');
    }

    return {
        ...state,
        bookmarkIds,
        bookmarks,
        toggleBookmarkLoading: false,
        toggleBookmarkError: false
    }
}
const toggleTenderBookmarkFailed = (state, action) => {    
    return {
        ...state,
        toggleBookmarkLoading: false,
        toggleBookmarkError: true
    }
}


const fetchTenderSearchStart = (state, action) => {
    return {
        ...state,
        tenderSearchBookmarksLoading: true,
        fetchTenderSearchError: false,
    }
}
const fetchTenderSearchSuccess = (state, action) => {
    return {
        ...state,
        tenderSearchBookmarksLoading: false,
        fetchTenderSearchError: false,
        tenderSearchBookmarks: action.response
    }
}
const fetchTenderSearchFailed = (state, action) => {
    return {
        ...state,
        tenderSearchBookmarksLoading: false,
        fetchTenderSearchError: true
    }
}



const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_STATE: return initialState;
        
        case actionTypes.TENDER_INPUT_CHANGED_HANDLER: return tenderInputChangedHandler(state, action);
        
        case actionTypes.FETCH_TENDER_MISC_START: return fetchTenderMiscStart(state, action);
        case actionTypes.FETCH_TENDER_MISC_SUCCESS: return fetchTenderMiscSuccess(state, action);
        case actionTypes.FETCH_TENDER_MISC_FAILED: return fetchTenderMiscFailed(state, action);
        
        case actionTypes.SEARCH_TENDER_START: return searchTenderStart(state, action);
        case actionTypes.SEARCH_TENDER_SUCCESS: return searchTenderSuccess(state, action);
        case actionTypes.SEARCH_TENDER_FAILED: return searchTenderFailed(state, action);
        
        case actionTypes.REMOVE_TENDER_START: return removeTenderStart(state, action);
        case actionTypes.REMOVE_TENDER_SUCCESS: return removeTenderSuccess(state, action);
        case actionTypes.REMOVE_TENDER_FAILED: return removeTenderFailed(state, action);
        
        case actionTypes.FETCH_MY_TENDERS_START: return fetchMyTendersStart(state, action);
        case actionTypes.FETCH_MY_TENDERS_SUCCESS: return fetchMyTendersSuccess(state, action);
        case actionTypes.FETCH_MY_TENDERS_FAILED: return fetchMyTendersFailed(state, action);
        
        case actionTypes.FETCH_TENDER_BOOKMARKS_START: return fetchTenderBookmarksStart(state, action);
        case actionTypes.FETCH_TENDER_BOOKMARKS_SUCCESS: return fetchTenderBookmarksSuccess(state, action);
        case actionTypes.FETCH_TENDER_BOOKMARKS_FAILED: return fetchTenderBookmarksFailed(state, action);
        
        case actionTypes.FETCH_TENDER_BOOKMARK_IDS_START: return fetchTenderBookmarkIdsStart(state, action);
        case actionTypes.FETCH_TENDER_BOOKMARK_IDS_SUCCESS: return fetchTenderBookmarkIdsSuccess(state, action);
        case actionTypes.FETCH_TENDER_BOOKMARK_IDS_FAILED: return fetchTenderBookmarkIdsFailed(state, action);
        
        case actionTypes.TOGGLE_TENDER_BOOKMARK_START: return toggleTenderBookmarkStart(state, action);
        case actionTypes.TOGGLE_TENDER_BOOKMARK_SUCCESS: return toggleTenderBookmarkSuccess(state, action);
        case actionTypes.TOGGLE_TENDER_BOOKMARK_FAILED: return toggleTenderBookmarkFailed(state, action);
        
        case actionTypes.FETCH_TENDER_SEARCH_START: return fetchTenderSearchStart(state, action);
        case actionTypes.FETCH_TENDER_SEARCH_SUCCESS: return fetchTenderSearchSuccess(state, action);
        case actionTypes.FETCH_TENDER_SEARCH_FAILED: return fetchTenderSearchFailed(state, action);
        

        default: return state;
    }
}

export default reducer;