import {delay, put} from 'redux-saga/effects';
import * as actionCreators from '../actions/index';
import authAxios from '../../CustomAxios/authAxios';
import { notify } from '../../components/UI/Notifications/Notification/Notification';

export function* loginSaga(action) {
    yield put(actionCreators.loginStart());

    try {
        const response = yield authAxios.post('/login.php', action.loginData);
        const expirationDate = yield new Date(response.data.expireAt*1000);
        let token = null;
        if(response.data.jwt) {
            token = `Bearer ${response.data.jwt}`;
            
            const {id, email, companyname, img} = response.data;
            const verified = response.data.verified === '1';
            
            yield localStorage.setItem('token', token);
            yield localStorage.setItem('expirationDate', expirationDate);
            yield localStorage.setItem('userId', id);
            yield localStorage.setItem('userEmail', email);
            yield localStorage.setItem('userName', companyname);
            yield localStorage.setItem('userImage', img);
            yield localStorage.setItem('verified', verified);
    
            yield put(actionCreators.loginSuccess(token, id, email, companyname, img, verified));
            yield put(actionCreators.setLogoutTimeout(expirationDate.getTime() - new Date().getTime()));
            
            // company calls
            yield put(actionCreators.fetchCompanyMisc(token));
            yield put(actionCreators.fetchCompanyBookmarkIds(token));
            
            // tender calls
            yield put(actionCreators.fetchTenderMisc(token));
            yield put(actionCreators.fetchTenderBookmarkIds(token));
            
            //callback
            if(action.callback)
            yield action.callback();
        } else {
            notify(response.data.message , "Danger");
        }
    } catch(error){
        console.log(error)
        notify("Greška u konekciji", "Danger");
        yield put(actionCreators.loginFailed(error));
    }
}

export function* setLogoutTimeoutSaga(action) {
    yield delay(action.expirationTime);
    yield put(actionCreators.logout())
}

export function* authCheckStateSaga(action) {
    const token = yield localStorage.getItem('token');
    if(!token) {
        yield put(actionCreators.logout());// or just return
    } else {
        const expirationDate = yield new Date(localStorage.getItem('expirationDate'));
        if(expirationDate > new Date()) {
            const userId = yield localStorage.getItem('userId');
            const userName = yield localStorage.getItem('userName');
            const userEmail = yield localStorage.getItem('userEmail');
            const userImage = yield localStorage.getItem('userImage') !== "null" ? localStorage.getItem('userImage') : null;
            const verified = JSON.parse(localStorage.getItem('verified'));
            
            yield put(actionCreators.loginSuccess(token, userId, userEmail, userName, userImage, verified));
            yield put(actionCreators.setLogoutTimeout(expirationDate.getTime() - new Date().getTime()));
            
            // company calls
            yield put(actionCreators.fetchCompanyMisc(token));
            yield put(actionCreators.fetchCompanyBookmarkIds(token));

            // tender calls
            yield put(actionCreators.fetchTenderMisc(token));
            yield put(actionCreators.fetchTenderBookmarkIds(token));
            // yield put(actionCreators.fetchMyTenders(token));
        } else {
            yield put(actionCreators.logout());
        }
    }
}

export function* logoutSaga(action) {
    yield localStorage.removeItem('token');
    yield localStorage.removeItem('expirationDate');
    yield localStorage.removeItem('userId');
    yield localStorage.removeItem('userName');
    yield localStorage.removeItem('userEmail');
    yield localStorage.removeItem('userImage');
    yield localStorage.removeItem('verified');

    yield put(actionCreators.resetState());
    yield put(actionCreators.logoutSuccess());
}

export function* registerSaga(action) {
    yield put(actionCreators.registerStart());

    try {
        yield authAxios.post('/register.php', action.registerData);
        yield put(actionCreators.registerSuccess());
        yield notify("Uspešno kreiran nalog", "Success");

        if(action.callback)
        yield action.callback();
    } catch(error){
        notify("Problem u konekciji", "Danger");
        yield put(actionCreators.registerFailed(error));
    }
}