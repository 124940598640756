import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter } from 'react-router-dom';

import navigationReducer from './store/reducers/navigation';
import companyReducer from './store/reducers/company';
import authReducer from './store/reducers/auth';
import tenderReducer from './store/reducers/tender';
import newsReducer from './store/reducers/news';
import blogReducer from './store/reducers/blog';
import dashboardReducer from './store/reducers/dashboard';
import nightReducer from './store/reducers/night';
import multiSearchReducer from './store/reducers/multiSearch';

import createSagaMiddleware from 'redux-saga';
import {watchAuth, watchCompany, watchNews, watchBlog, watchTender, watchDashboard} from './store/sagas/index';

import {Provider} from 'react-redux';
import {createStore, combineReducers, applyMiddleware, compose} from 'redux';

// instalirati Redux DevTools u chrome
// const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
let composeEnhancers = null;
if (process.env.NODE_ENV === 'development') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
    composeEnhancers = compose;
}


const rootReducer = combineReducers({
  navigation: navigationReducer,
  company: companyReducer,
  auth: authReducer,
  tender: tenderReducer,
  news: newsReducer,
  blog: blogReducer,
  dashboard: dashboardReducer,
  night: nightReducer,
  multiSearch: multiSearchReducer,
});


const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(watchCompany);
sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchTender);
sagaMiddleware.run(watchNews);
sagaMiddleware.run(watchBlog);
sagaMiddleware.run(watchDashboard);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
