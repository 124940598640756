import * as actionTypes from '../actions/actionTypes';
const verifiedInitial = JSON.parse(localStorage.getItem('verified')) || false;

const initialState = {
    token: null,
    userId: null,
    userEmail: null,
    userName: null,
    userImage: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    verified: verifiedInitial
}

const setVerified = (state, action) => {
    const {verified} = action;
    return {
        ...state,
        verified
    }
}

export const loginStart =(state, action) => {
    return {
        ...state,
        loading: true,
        error: null,
    }
}
export const loginSuccess =(state, action) => {
    return {
        ...state,
        token: action.token,
        userId: action.userId,
        userEmail: action.userEmail,
        userName: action.userName,
        userImage: action.userImage,
        verified: action.verified,
        loading: false,
    }
}
export const loginFailed =(state, action) => {
    return {
        ...state,
        error: action.error,
        loading: false
    }
}

export const logoutSuccess = (state, action) => {
    return {
        ...state,
        token: null,
        userId: null,
        userEmail: null
    }
}

export const registerStart =(state, action) => {
    return {
        ...state,
        loading: true,
        error: null,
    }
}
export const registerSuccess =(state, action) => {
    return {
        ...state,
        loading: false
    }
}
export const registerFailed =(state, action) => {
    return {
        ...state,
        error: action.error,
        loading: false
    }
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_STATE: return initialState;
        
        case actionTypes.SET_VERIFIED: return setVerified(state, action);

        case actionTypes.LOGIN_START: return loginStart(state, action);
        case actionTypes.LOGIN_SUCCESS: return loginSuccess(state, action);
        case actionTypes.LOGIN_FAILED: return loginFailed(state, action);

        
        case actionTypes.REGISTER_START: return registerStart(state, action);
        case actionTypes.REGISTER_SUCCESS: return registerSuccess(state, action);
        case actionTypes.REGISTER_FAILED: return registerFailed(state, action);

        case actionTypes.LOGOUT_SUCCESS: return logoutSuccess(state, action);

        default: return state;
    }
}

export default reducer;