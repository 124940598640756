const emailValidationPattern = /^\w+([.-]?\w+)+@\w+([.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/;

const urlValidation = (url) => {
    let urlIsValid = true;
    
    urlIsValid = (url.startsWith('http://') || url.startsWith('ftp://') || url.startsWith('https://')) && urlIsValid;
    urlIsValid = url.includes('.') && urlIsValid;
    urlIsValid = !url.includes(' ') && urlIsValid;
    urlIsValid = !url.includes('"') && urlIsValid;
    urlIsValid = !url.includes("'") && urlIsValid;

    return urlIsValid;
}

export const addHttpsToUrl = (url) => {
    return url.startsWith('www') ? `https://${url}` : url;
}

export const checkValidity = (value, rules, passwordToMatch='') => {
    if(!rules) return true;
    let isValid = true;
    let message = '';
    if(rules.required) {
        isValid = value.trim() !== '' && isValid;
        if(!isValid) {
            message = 'Ovo polje je obavezno';
            return {isValid, message}
        }
    }
    if(rules.dateRequired) {
        isValid = !!value && isValid;
        if(!isValid) {
            message = 'Ovo polje je obavezno';
            return {isValid, message}
        }
    }
    if(rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
        if(value.length < rules.minLength) {
            message =`Minimalan broj karaktera je ${rules.minLength}`;
            return {isValid, message}
        }
    }
    if(rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
        if(value.length > rules.maxLength) {
            message =`Maksimalan broj karaktera je ${rules.maxLength}`;
            return {isValid, message}
        }
    }
    if(rules.url) {
        isValid = urlValidation(value) && isValid;
        if(!isValid) {
            message = 'Unesite ispravan link';
            return {isValid, message}
        }
    }
    if(rules.email) {
        isValid = value.match(emailValidationPattern) && isValid;
        message = !isValid && 'Unesite ispravan email';
    }
    return {isValid, message};
}

export const replaceSpecialChars = (str) => {
    let updatedStr;
    if(str && str.length > 0) {
        updatedStr = str.replace(/š/gi, 's');
        updatedStr = updatedStr.replace(/ć/gi, 'c');
        updatedStr = updatedStr.replace(/č/gi, 'c');
        updatedStr = updatedStr.replace(/ž/gi, 'z');
        updatedStr = updatedStr.replace(/đ/gi, 'dj');
        return updatedStr;
    } else {
        return str;
    }
}

export const slugifyString = (str) => {
    let updatedStr = str.toLowerCase();
    updatedStr = replaceSpecialChars(updatedStr);
    updatedStr = updatedStr.replace(/[/\\?%*:|"<>]/g, '');
    updatedStr = updatedStr.replace(/ /gi, '-');
    if(updatedStr.includes('--')) {
        while(updatedStr.includes('--')) {
            updatedStr = updatedStr.replace(/--/gi, '-');
        }
    }
    return updatedStr;
}

export const stringLimiter = (str, length) => {
    let updatedString = str;
    if(str.length > length)
    updatedString = str.slice(0, length) + '...';
    return updatedString;
}

export const isObject = (item) => {
    return item !== null && item !== undefined && Object.keys(item).length > 0;
}
export const htmlDecode = (input) => {
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
}
export const formatDate = (date) => {
    const formattedDate = new Date(date);
    const mL = ['Januar', 'Februar', 'Mart', 'April', 'Maj', 'Jun', 'Jul', 'Avgust', 'Septembar', 'Oktobar', 'Novembar', 'Decembar'];
    // const mS = ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Avg', 'Sep', 'Okt', 'Nov', 'Dec'];

    const day = formattedDate.getDate();
    const month = formattedDate.getMonth();
    const year = formattedDate.getFullYear();
    return `${day}. ${mL[month]} ${year}.`;
}

export const dateYyyyMmDd = (date) => {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    return `${year}-${month+1}-${day}`;
}


export const checkSpaces = (str) => {
    return (str && str.includes(' ')) ? false : str;
}

export const imageCheck = (image_url, setImage) => {
    const img = new Image();
    img.onload = () => setImage(image_url); 
    img.onerror = () => setImage(false);
    img.src = image_url;
}