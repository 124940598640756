import {takeEvery} from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';

import { 
    fetchCompanyMiscSaga,
    searchCompanySaga,
    fetchCompanyBookmarksSaga,
    fetchCompanyBookmarkIdsSaga,
    toggleCompanyBookmarkSaga,
    fetchCompanySearchSaga,
    fetchMyCompanySaga
} from './company';

import {
    loginSaga,
    authCheckStateSaga,
    logoutSaga,
    setLogoutTimeoutSaga,
    registerSaga
} from './auth';

import {
    fetchTenderMiscSaga,
    searchTenderSaga,
    removeTenderSaga,
    fetchMyTendersSaga,
    fetchTenderBookmarksSaga,
    fetchTenderBookmarkIdsSaga,
    fetchTenderSearchSaga,
    toggleTenderBookmarkSaga,
} from './tender';

import {
    searchNewsSaga,
    searchMyNewsSaga,
    removeNewsSaga,
} from './news';

import {
    searchBlogSaga,
    searchMyBlogSaga,
    removeBlogSaga,
} from './blog';

import {
    fetchDashboardDataSaga
} from './dashboard';

export function* watchCompany() {
    yield takeEvery(actionTypes.FETCH_COMPANY_MISC, fetchCompanyMiscSaga);
    yield takeEvery(actionTypes.SEARCH_COMPANY, searchCompanySaga);
    yield takeEvery(actionTypes.FETCH_COMPANY_BOOKMARKS, fetchCompanyBookmarksSaga);
    yield takeEvery(actionTypes.FETCH_COMPANY_BOOKMARK_IDS, fetchCompanyBookmarkIdsSaga);
    yield takeEvery(actionTypes.TOGGLE_COMPANY_BOOKMARK, toggleCompanyBookmarkSaga);
    yield takeEvery(actionTypes.FETCH_COMPANY_SEARCH, fetchCompanySearchSaga);
    yield takeEvery(actionTypes.FETCH_MY_COMPANY, fetchMyCompanySaga);
}

export function* watchAuth() {
    yield takeEvery(actionTypes.LOGIN, loginSaga);
    yield takeEvery(actionTypes.SET_LOGOUT_TIMEOUT, setLogoutTimeoutSaga);
    yield takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga);
    yield takeEvery(actionTypes.LOGOUT, logoutSaga);
    yield takeEvery(actionTypes.REGISTER, registerSaga);
}

export function* watchTender() {
    yield takeEvery(actionTypes.FETCH_TENDER_MISC, fetchTenderMiscSaga);
    yield takeEvery(actionTypes.SEARCH_TENDER, searchTenderSaga);
    yield takeEvery(actionTypes.REMOVE_TENDER, removeTenderSaga);
    yield takeEvery(actionTypes.FETCH_MY_TENDERS, fetchMyTendersSaga);
    yield takeEvery(actionTypes.FETCH_TENDER_BOOKMARKS, fetchTenderBookmarksSaga);
    yield takeEvery(actionTypes.FETCH_TENDER_BOOKMARK_IDS, fetchTenderBookmarkIdsSaga);
    yield takeEvery(actionTypes.FETCH_TENDER_SEARCH, fetchTenderSearchSaga);
    yield takeEvery(actionTypes.TOGGLE_TENDER_BOOKMARK, toggleTenderBookmarkSaga);
}

export function* watchNews() {
    yield takeEvery(actionTypes.SEARCH_NEWS, searchNewsSaga);
    yield takeEvery(actionTypes.SEARCH_MY_NEWS, searchMyNewsSaga);
    yield takeEvery(actionTypes.REMOVE_NEWS, removeNewsSaga);
}

export function* watchBlog() {
    yield takeEvery(actionTypes.SEARCH_BLOG, searchBlogSaga);
    yield takeEvery(actionTypes.SEARCH_MY_BLOG, searchMyBlogSaga);
    yield takeEvery(actionTypes.REMOVE_BLOG, removeBlogSaga);
}

export function* watchDashboard() {
    yield takeEvery(actionTypes.FETCH_DASHBOARD_DATA, fetchDashboardDataSaga);
}