import * as actionTypes from './actionTypes';

export const companyInputChangedHandler = (value, inputName) => {
    return {
        type: actionTypes.COMPANY_INPUT_CHANGED_HANDLER,
        value,
        inputName
    }
}

// MISC
export const fetchCompanyMisc = (token, callback) => { // SAGA
    return {
        type: actionTypes.FETCH_COMPANY_MISC,
        token,
        callback
    }
}
export const fetchCompanyMiscStart = () => {
    return {
        type: actionTypes.FETCH_COMPANY_MISC_START
    }
}
export const fetchCompanyMiscSuccess  = misc => {
    return {
        type: actionTypes.FETCH_COMPANY_MISC_SUCCESS,
        misc
    }
}
export const fetchCompanyMiscFailed = (error) => {
    return {
        type: actionTypes.FETCH_COMPANY_MISC_FAILED,
        error
    }
}

// COMPANIES SEARCH
export const searchCompany = (token, searchData, pageNumber = 1, callback) => { //SAGA
    return {
        type: actionTypes.SEARCH_COMPANY,
        token,
        searchData,
        pageNumber,
        callback
    };
}
export const searchCompanyStart = () => {
    return {
        type: actionTypes.SEARCH_COMPANY_START
    };
}
export const searchCompanySuccess = (companies, companiesSearchData, companiesResultsCount) => {
    return {
        type: actionTypes.SEARCH_COMPANY_SUCCESS,
        companies,
        companiesSearchData,
        companiesResultsCount,
    };
}
export const searchCompanyFailed = (error) => {
    return {
        type: actionTypes.SEARCH_COMPANY_FAILED,
        error
    };
}

// COMPANIES BOOKMARKS
export const fetchCompanyBookmarks = (token, searchData, pageNumber = 1, callback) => { // SAGA
    return {
        type: actionTypes.FETCH_COMPANY_BOOKMARKS,
        token,
        searchData,
        pageNumber,
        callback
    }
}
export const fetchCompanyBookmarksStart = () => {
    return {
        type: actionTypes.FETCH_COMPANY_BOOKMARKS_START
    }
}
export const fetchCompanyBookmarksSuccess = (bookmarks, bookmarksResultsCount) => {
    return {
        type: actionTypes.FETCH_COMPANY_BOOKMARKS_SUCCESS,
        bookmarks,
        bookmarksResultsCount,
    }
}
export const fetchCompanyBookmarksFailed = () => {
    return {
        type: actionTypes.FETCH_COMPANY_BOOKMARKS_FAILED
    }
}

export const fetchCompanyBookmarkIds = (token) => { // SAGA
    return {
        type: actionTypes.FETCH_COMPANY_BOOKMARK_IDS,
        token,
    }
}
export const fetchCompanyBookmarkIdsStart = () => {
    return {
        type: actionTypes.FETCH_COMPANY_BOOKMARK_IDS_START
    }
}
export const fetchCompanyBookmarkIdsSuccess = (bookmarkIds) => {
    return {
        type: actionTypes.FETCH_COMPANY_BOOKMARK_IDS_SUCCESS,
        bookmarkIds,
    }
}
export const fetchCompanyBookmarkIdsFailed = () => {
    return {
        type: actionTypes.FETCH_COMPANY_BOOKMARK_IDS_FAILED
    }
}
// TOGGLE COMPANY BOOKMARK
export const toggleCompanyBookmark = (token, companyId, callback) => { // SAGA
    return {
        type: actionTypes.TOGGLE_COMPANY_BOOKMARK,
        token,
        companyId,
        callback
    }
}
export const toggleCompanyBookmarkStart = () => {
    return {
        type: actionTypes.TOGGLE_COMPANY_BOOKMARK_START
    }
}
export const toggleCompanyBookmarkSuccess = (companyId, responseMessage, callback) => {
    return {
        type: actionTypes.TOGGLE_COMPANY_BOOKMARK_SUCCESS,
        companyId,
        responseMessage,
        callback
    }
}
export const toggleCompanyBookmarkFailed = () => {
    return {
        type: actionTypes.TOGGLE_COMPANY_BOOKMARK_FAILED
    }
}

//COMPANIES FETCH SEARCH BOOKMARKS
export const fetchCompanySearch = (token, callback) => {
    return {
        type: actionTypes.FETCH_COMPANY_SEARCH,
        token,
        callback
    }
}
export const fetchCompanySearchStart = () => {
    return {
        type: actionTypes.FETCH_COMPANY_SEARCH_START
    }
}
export const fetchCompanySearchSuccess = (response) => {
    return {
        type: actionTypes.FETCH_COMPANY_SEARCH_SUCCESS,
        response
    }
}
export const fetchCompanySearchFailed = (error) => {
    return {
        type: actionTypes.FETCH_COMPANY_SEARCH_FAILED,
        error
    }
}

//COMPANIES FETCH SINGLE COMPANY
export const fetchMyCompany = (id, token, callback) => {
    return {
        type: actionTypes.FETCH_MY_COMPANY,
        id,
        token,
        callback
    }
}
export const fetchMyCompanyStart = () => {
    return {
        type: actionTypes.FETCH_MY_COMPANY_START
    }
}
export const fetchMyCompanySuccess = (myCompany) => {
    return {
        type: actionTypes.FETCH_MY_COMPANY_SUCCESS,
        myCompany
    }
}
export const fetchMyCompanyFailed = (error) => {
    return {
        type: actionTypes.FETCH_MY_COMPANY_FAILED,
        error
    }
}

