import React, { useContext } from 'react';
import Context from '../../../../hoc/Layout/Context';
import classes from './DrawerToggle.module.css';

const DrawerToggle = () => {
    const context = useContext(Context);
    return (
        <div 
            onClick={context.toggleMenu}
            className={classes.DrawerToggle}
        >
            <i className="fas fa-bars fa-lg"></i>
        </div>
    );
};

export default DrawerToggle;