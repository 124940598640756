import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import PageTitle from '../../../components/PageTitle/PageTitle';
import MainButton from '../../../components/UI/Buttons/MainButton/MainButton';
import { checkValidity } from '../../../shared/utility';
import classes from './Login.module.css';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import MaterialInput from '../../../components/UI/Inputs/MaterialInput/MaterialInput';
import Icons from '../../../components/UI/Icons/MaterialIcons';

class Login extends Component {
    state = {
        breadCrumbs: [
            {
                link: null,
                caption: 'Prijava'
            }
        ],
        authForm: {
            email: {
                elementType: 'login-input',
                elementConfig: {
                    type: 'text',
                    // placeholder: 'Email',
                    name:'email'
                },
                label: 'Email',
                icon: Icons.Message,
                value: '',
                validationRules: {
                    required:true
                },
                valid:false,
                focused: false,
                touched: false,
                validationErrorMessage: '',
            },
            password: {
                elementType: 'login-input',
                elementConfig: {
                    type: 'password',
                    // placeholder: 'Šifra',
                    name:'password'
                },
                label: 'Šifra',
                icon: Icons.Lock,
                value: '',
                validationRules: {
                    required:true
                },
                valid:false,
                focused: false,
                touched: false,
                validationErrorMessage: '',
            }
        },
        authFormIsValid: false
    }
    componentDidMount() {        
        window.scrollTo(0, 0);
    }
    
    inputChangedHandler = (value, inputIdentifier) => {
        const validity = checkValidity(value, this.state.authForm[inputIdentifier].validationRules);
        const updatedAuthForm = {
            ...this.state.authForm 
        }
        const updatedElement = {
            ...updatedAuthForm[inputIdentifier]
        }
        
        updatedElement.value = value;
        updatedElement.touched = true;
        updatedElement.valid = validity.isValid;
        updatedElement.validationErrorMessage = validity.message;

        updatedAuthForm[inputIdentifier] = updatedElement;

        
        let authFormIsValid = true;
        for(let element in updatedAuthForm) {
            authFormIsValid = updatedAuthForm[element].valid && authFormIsValid;
        }

        this.setState({
            authForm: updatedAuthForm,
            authFormIsValid
        })
    }
    submitHandler = (event) => {
        event.preventDefault();
        const loginData = {
            email: this.state.authForm.email.value,
            password: this.state.authForm.password.value
        }
        this.props.onLogin(loginData);
    } 
    
    focusHandler = (inputIdentifier, isFocused) => {
        const updatedAuthForm = {
            ...this.state.authForm 
        }
        const updatedElement = {
            ...updatedAuthForm[inputIdentifier]
        }

        updatedElement.focused = isFocused;
        updatedAuthForm[inputIdentifier] = updatedElement;

        this.setState({
            authForm: updatedAuthForm
        })
    }

    render() {
        const formElementsArray = [];
        for(let key in this.state.authForm) {
            formElementsArray.push({
                id:key,
                config: this.state.authForm[key]
            })
        }
        
        let authRedirect = null;
        if(this.props.isAuthenticated) {
            authRedirect = <Redirect to={this.props.authRedirectPath} />
        }
        
        let form = formElementsArray.map(formElement => (
            <MaterialInput
                key={formElement.id}
                elementType={formElement.config.elementType}
                elementConfig={formElement.config.elementConfig}
                value={formElement.config.value}
                icon={formElement.config.icon}
                label={formElement.config.label}
                invalid={!formElement.config.valid}
                validationErrorMessage={formElement.config.validationErrorMessage}
                shouldValidate={formElement.config.validationRules !== {}}
                validationRules={formElement.config.validationRules}
                touched={formElement.config.touched}
                changed={(event)=>this.inputChangedHandler(event.target.value, formElement.id)}
                isFocused={formElement.config.focused}
                focused={()=>this.focusHandler(formElement.id, true)}
                blurred={()=>this.focusHandler(formElement.id, false)}
            />
        ));
        return (
            <>
                <PageTitle breadCrumbs={this.state.breadCrumbs}>
                    <h2>Prijava</h2>
                </PageTitle>
                <div className={`${classes.Login} fadeIn`}>
                    {authRedirect}
                    <h2>Dobrodošli nazad!</h2>
                    <div className={classes.Action}>
                        Nemate nalog? <Link to='/register'>Registrujte se</Link>
                    </div>
                    <div className={classes.FormHolder}>
                        <form autoComplete="on" onSubmit={(event) => this.submitHandler(event)}>
                            {form}
                            <MainButton
                                withArrow
                                btntype="submit"
                                disabled={!this.state.authFormIsValid}
                            >
                                Prijava
                            </MainButton>
                        </form>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onLogin: (loginData, callback) => dispatch(actions.login(loginData, callback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);