// import { notify } from '../../components/UI/Notifications/Notification/Notification';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    searchData: null,

    blogLoading: true,
    blogError: false,
    blog: [],
    blogResultsCount: null,
    
    myBlogLoading: true,
    myBlogError: false,
    myBlog: [],
    myBlogResultsCount: null,

    removeBlogLoading: false,
    removeBlogError: false,
};

const searchBlogStart = (state, action) => {
    return {
        ...state,
        blogLoading: true,
        blogError: false,
    }
}
const searchBlogSuccess = (state, action) => {
    const {blog, blogResultsCount} = action;

    return {
        ...state,
        blogLoading: false,
        blogError: false,
        blog,
        blogResultsCount,
    }
}
const searchBlogFailed = (state, action) => {
    return {
        ...state,
        myBlogLoading: false,
        myBlogError: action.error,
    }
}

const searchMyBlogStart = (state, action) => {
    return {
        ...state,
        myBlogLoading: true,
        myBlogError: false,
    }
}
const searchMyBlogSuccess = (state, action) => {
    const {myBlog, myBlogResultsCount} = action;

    return {
        ...state,
        myBlogLoading: false,
        myBlogError: false,
        myBlog,
        myBlogResultsCount,
    }
}
const searchMyBlogFailed = (state, action) => {
    return {
        ...state,
        myBlogLoading: false,
        myBlogError: action.error,
    }
}
// remove blog
const removeBlogStart = (state, action) => {    
    return {
        ...state,
        removeBlogLoading: true,
        removeBlogError: false
    }
}
const removeBlogSuccess = (state, action) => {
    return {
        ...state,
        removeBlogLoading: false,
        removeBlogError: false
    }
}
const removeBlogFailed = (state, action) => {    
    return {
        ...state,
        removeBlogLoading: false,
        removeBlogError: true
    }
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_STATE: return initialState;
        
        case actionTypes.SEARCH_BLOG_START: return searchBlogStart(state, action);
        case actionTypes.SEARCH_BLOG_SUCCESS: return searchBlogSuccess(state, action);
        case actionTypes.SEARCH_BLOG_FAILED: return searchBlogFailed(state, action);
        
        case actionTypes.SEARCH_MY_BLOG_START: return searchMyBlogStart(state, action);
        case actionTypes.SEARCH_MY_BLOG_SUCCESS: return searchMyBlogSuccess(state, action);
        case actionTypes.SEARCH_MY_BLOG_FAILED: return searchMyBlogFailed(state, action);
        
        case actionTypes.REMOVE_BLOG_START: return removeBlogStart(state, action);
        case actionTypes.REMOVE_BLOG_SUCCESS: return removeBlogSuccess(state, action);
        case actionTypes.REMOVE_BLOG_FAILED: return removeBlogFailed(state, action);
        
        default: return state;
    }
}

export default reducer;