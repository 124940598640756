// import { notify } from '../../components/UI/Notifications/Notification/Notification';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    searchData: null,

    newsLoading: true,
    newsError: false,
    news: [],
    newsResultsCount: null,

    myNewsLoading: true,
    myNewsError: false,
    myNews: [],
    myNewsResultsCount: null,

    removeNewsLoading: false,
    removeNewsError: false,
};

const searchNewsStart = (state, action) => {
    return {
        ...state,
        newsLoading: true,
        newsError: false,
    }
}
const searchNewsSuccess = (state, action) => {
    const {news, newsResultsCount} = action;

    return {
        ...state,
        newsLoading: false,
        newsError: false,
        news,
        newsResultsCount,
    }
}
const searchNewsFailed = (state, action) => {
    return {
        ...state,
        myNewsLoading: false,
        myNewsError: action.error,
    }
}

const searchMyNewsStart = (state, action) => {
    return {
        ...state,
        myNewsLoading: true,
        myNewsError: false,
    }
}
const searchMyNewsSuccess = (state, action) => {
    const {myNews, myNewsResultsCount} = action;

    return {
        ...state,
        myNewsLoading: false,
        myNewsError: false,
        myNews,
        myNewsResultsCount,
    }
}
const searchMyNewsFailed = (state, action) => {
    return {
        ...state,
        myNewsLoading: false,
        myNewsError: action.error,
    }
}
// remove news
const removeNewsStart = (state, action) => {    
    return {
        ...state,
        removeNewsLoading: true,
        removeNewsError: false
    }
}
const removeNewsSuccess = (state, action) => {
    return {
        ...state,
        removeNewsLoading: false,
        removeNewsError: false
    }
}
const removeNewsFailed = (state, action) => {    
    return {
        ...state,
        removeNewsLoading: false,
        removeNewsError: true
    }
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_STATE: return initialState;
        
        case actionTypes.SEARCH_NEWS_START: return searchNewsStart(state, action);
        case actionTypes.SEARCH_NEWS_SUCCESS: return searchNewsSuccess(state, action);
        case actionTypes.SEARCH_NEWS_FAILED: return searchNewsFailed(state, action);
        
        case actionTypes.SEARCH_MY_NEWS_START: return searchMyNewsStart(state, action);
        case actionTypes.SEARCH_MY_NEWS_SUCCESS: return searchMyNewsSuccess(state, action);
        case actionTypes.SEARCH_MY_NEWS_FAILED: return searchMyNewsFailed(state, action);
        
        case actionTypes.REMOVE_NEWS_START: return removeNewsStart(state, action);
        case actionTypes.REMOVE_NEWS_SUCCESS: return removeNewsSuccess(state, action);
        case actionTypes.REMOVE_NEWS_FAILED: return removeNewsFailed(state, action);
        
        default: return state;
    }
}

export default reducer;