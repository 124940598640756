import React from 'react';
import classes from './SideDrawer.module.css';
import Logo from '../../Logo/Logo';
import NavigationItems from '../NavigationItems/NavigationItems';
import Backdrop from '../../UI/Backdrop/Backdrop'


const SideDrawer = (props) => {
    return (
        <>
            <Backdrop
                show={props.show} 
                hide={props.hide}
            />
            <div 
                className={`${classes.SideDrawer} ${props.show ? classes.Open : classes.Close}`}
            >
                <div className={classes.Logo}>
                    <Logo />
                </div>
                <div className={classes.Items}>
                    <NavigationItems  />
                </div>
            </div>
        </>
    );
};

export default SideDrawer;