import React from 'react';
import classes from './SideBar.module.css';
import NavigationItems from '../NavigationItems/NavigationItems';

const SideBar = () => {
    return (
        <div className={classes.SideBar}>
            <NavigationItems />
        </div>
    );
};

export default SideBar;