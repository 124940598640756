import {put} from 'redux-saga/effects';
import * as actionCreators from '../actions/index';
import blogAxios from '../../CustomAxios/blogAxios';
import { notify } from '../../components/UI/Notifications/Notification/Notification';

export function* searchBlogSaga(action) {
    yield put(actionCreators.searchBlogStart());
    
    const {token, searchData, pageNumber, callback} = action;
    if(!searchData.created_by)
    searchData.created_by = '';

    try {
        const response = yield blogAxios.post('/search.php', {...searchData, pageNumber}, {
            headers:{
                Authorization: token
            }
        });
        const blog = yield response.data[0].data;
        const blogResultsCount = yield response.data[0].resultsCount;
        
        yield put(actionCreators.searchBlogSuccess(blog, blogResultsCount));

        if(callback)
        yield callback();
    } catch (error) {
        console.log(error)
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.searchBlogFailed(error))
    }
}

export function* searchMyBlogSaga(action) {
    yield put(actionCreators.searchMyBlogStart());
    
    const {token, searchData, pageNumber, callback} = action;
    if(!searchData.created_by)
    searchData.created_by = '';

    try {
        const response = yield blogAxios.post('/search.php', {...searchData, pageNumber}, {
            headers:{
                Authorization: token
            }
        });
        const myBlog = yield response.data[0].data;
        const myBlogResultsCount = yield response.data[0].resultsCount;
        
        yield put(actionCreators.searchMyBlogSuccess(myBlog, myBlogResultsCount));

        if(callback)
        yield callback();
    } catch (error) {
        console.log(error)
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.searchMyBlogFailed(error))
    }
}

export function* removeBlogSaga(action) {
    try {
        yield put(actionCreators.removeBlogStart());
        
        const response = yield blogAxios.get('/delete.php?id=' + action.blogId + '&image=' + action.image,  {
            headers:{
                Authorization: action.token
            }}
        );
        response.data.success ?
        yield notify('Uklonjeno', 'Success') :
        yield notify('Stavka je već obrisana', 'Danger');

        yield put(actionCreators.removeBlogSuccess());
    } catch (error) {
        console.log(error)
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.removeBlogFailed());
    }
}