import React from 'react';
import classes from './MultiSearch.module.css';
import CompanySearch from './CompanySearch/CompanySearch';
import TenderSearch from './TenderSearch/TenderSearch';
import NewsSearch from './NewsSearch/NewsSearch';
import BlogSearch from './BlogSearch/BlogSearch';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

const MultiSearch = (props) => {
    const searchBar = {
        companies : <CompanySearch />,
        tenders : <TenderSearch />,
        news : <NewsSearch />,
        blog : <BlogSearch />,
    };
    const nav = <div className={classes.Nav}>
        <h2 
            className={`${classes.NavItem} ${props.active === 'companies' ? classes.Active : ''}`} 
            onClick={() => props.onSetActiveSearch('companies')}
        >
            Firme
        </h2>
        <h2 
            className={`${classes.NavItem} ${props.active === 'tenders' ? classes.Active : ''}`} 
            onClick={() => props.onSetActiveSearch('tenders')}
        >
            Tenderi
        </h2>
        <h2 
            className={`${classes.NavItem} ${props.active === 'news' ? classes.Active : ''}`} 
            onClick={() => props.onSetActiveSearch('news')}
        >
            Vesti
        </h2>
        <h2 
            className={`${classes.NavItem} ${props.active === 'blog' ? classes.Active : ''}`} 
            onClick={() => props.onSetActiveSearch('blog')}
        >
            Blog
        </h2>
    </div>;
    
    return (
        <div className={`${classes.MultiSearch} fadeIn`}>
            {nav}
            <div className={classes.SearchBar}>
                {searchBar[props.active]}
            </div>
        </div>
    );
    
}

const mapStateToProps = state => {
    return {
        active: state.multiSearch.active,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onSetActiveSearch: (active) => dispatch(actions.setActiveSearch(active)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiSearch);