import {put} from 'redux-saga/effects';
import * as actionCreators from '../actions/index';
import companyAxios from '../../CustomAxios/companyAxios';
import { notify } from '../../components/UI/Notifications/Notification/Notification';

export function* fetchCompanyMiscSaga(action) {
    try {
        yield put(actionCreators.fetchCompanyMiscStart());
        
        const response = yield companyAxios.get('/read_misc.php',  {
            headers:{
                Authorization: action.token
            }}
        );
        const data = yield response.data[0].data;
        const {categories, locations} = data;

        const misc = {categories, locations};
        yield put(actionCreators.fetchCompanyMiscSuccess(misc));

        if(action.callback)
        yield action.callback();
    }
    catch(error) {
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.fetchCompanyMiscFailed(error))
    } 
}

export function* searchCompanySaga(action) {
    yield put(actionCreators.searchCompanyStart());
    
    const {searchData, pageNumber, token, callback} = action;
    try {
        const response = yield companyAxios.post('/search.php', {...searchData, pageNumber}, {
            headers:{
                Authorization: token
            }
        });
        const companies = yield response.data[0].data;
        const resultsCount = yield response.data[0].resultsCount;
        yield put(actionCreators.searchCompanySuccess(
            companies, 
            searchData, 
            resultsCount
            ));

        if(callback)
        yield callback();
    } catch (error) {
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.searchCompanyFailed(error))
    }
}

export function* fetchCompanyBookmarksSaga(action) {
    try {
        yield put(actionCreators.fetchCompanyBookmarksStart());

        const {token, searchData, pageNumber, callback} = action;
        const response = yield companyAxios.post('/bookmark_read_all.php', {...searchData, pageNumber}, {
            headers:{
                Authorization: token
            }}
        );
        const bookmarks = yield response.data[0].data;
        const resultsCount = yield response.data[0].resultsCount;

        yield put(actionCreators.fetchCompanyBookmarksSuccess(bookmarks, resultsCount));

        if(callback)
        yield callback();
    }
    catch(error) {
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.fetchCompanyBookmarksFailed())
    }
}
export function* fetchCompanyBookmarkIdsSaga(action) {
    try {
        yield put(actionCreators.fetchCompanyBookmarkIdsStart());

        const {token} = action;
        const response = yield companyAxios.get('/bookmark_read_all_ids.php',  {
            headers:{
                Authorization: token
            }}
        );
        const bookmarkIds = yield response.data[0];

        yield put(actionCreators.fetchCompanyBookmarkIdsSuccess(bookmarkIds));
    }
    catch(error) {
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.fetchCompanyBookmarkIdsFailed())
    }
}

export function* toggleCompanyBookmarkSaga(action) {
    try {
        yield put(actionCreators.toggleCompanyBookmarkStart());
        
        const bookmarkData = {bookmark_id: action.companyId};
        const response = yield companyAxios.post('/bookmark.php', bookmarkData, {
            headers:{
                Authorization: action.token
            }
        });

        yield put(actionCreators.toggleCompanyBookmarkSuccess(action.companyId, response.data.message, action.callback));
    } catch (error) {
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.toggleCompanyBookmarkFailed());
    }
}

export function* fetchCompanySearchSaga(action) {
    try {
        yield put(actionCreators.fetchCompanySearchStart());
        const response = yield companyAxios.get('/bookmark_search_read_all.php',  {
            headers:{
                Authorization: action.token
            }}
        );
        const data = yield response.data[0].data;
        yield put(actionCreators.fetchCompanySearchSuccess(data));

        if(action.callback)
        yield action.callback();
    }
    catch(error) {
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.fetchCompanySearchFailed())
    }
}

export function* fetchMyCompanySaga(action) {
    try {
        yield put(actionCreators.fetchMyCompanyStart());
        const response = yield companyAxios.get('/read_single.php?id=' + action.id,  {
            headers:{
                Authorization: action.token
            }}
        );
        const data = yield response.data[0];
        yield put(actionCreators.fetchMyCompanySuccess(data));
        
        if(action.callback)
        yield action.callback();
    }
    catch(error) {
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.fetchMyCompanyFailed())
    }
}