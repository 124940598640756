import * as actionTypes from './actionTypes';

export const tenderInputChangedHandler = (value, inputName) => {
    return {
        type: actionTypes.TENDER_INPUT_CHANGED_HANDLER,
        value,
        inputName
    }
}

// MISC
export const fetchTenderMisc = (token, callback) => { // SAGA
    return {
        type: actionTypes.FETCH_TENDER_MISC,
        token,
        callback
    }
}
export const fetchTenderMiscStart = () => {
    return {
        type: actionTypes.FETCH_TENDER_MISC_START
    }
}
export const fetchTenderMiscSuccess = misc => {
    return {
        type: actionTypes.FETCH_TENDER_MISC_SUCCESS,
        misc
    }
}
export const fetchTenderMiscFailed = (error) => {
    return {
        type: actionTypes.FETCH_TENDER_MISC_FAILED,
        error
    }
}

// TENDERS SEARCH
export const searchTender = (token, searchData, pageNumber = 1, callback) => { //SAGA
    return {
        type: actionTypes.SEARCH_TENDER,
        token,
        searchData,
        pageNumber,
        callback
    };
}
export const searchTenderStart = () => {
    return {
        type: actionTypes.SEARCH_TENDER_START
    };
}
export const searchTenderSuccess = (tenders, tendersSearchData, tendersResultsCount, tendersPageNumber) => {
    return {
        type: actionTypes.SEARCH_TENDER_SUCCESS,
        tenders,
        tendersSearchData,
        tendersResultsCount,
        tendersPageNumber
    };
}
export const searchTenderFailed = (error) => {
    return {
        type: actionTypes.SEARCH_TENDER_FAILED,
        error
    };
}

// REMOVE TENDER 
export const removeTender = (token, tenderId) => { // SAGA
    return {
        type: actionTypes.REMOVE_TENDER,
        token,
        tenderId
    }
}
export const removeTenderStart = () => {
    return {
        type: actionTypes.REMOVE_TENDER_START
    }
}
export const removeTenderSuccess = (tenderId) => {
    return {
        type: actionTypes.REMOVE_TENDER_SUCCESS,
        tenderId
    }
}
export const removeTenderFailed = () => {
    return {
        type: actionTypes.REMOVE_TENDER_FAILED
    }
}

//TENDERS FETCH SEARCH BOOKMARKS
export const fetchMyTenders = (token, searchData , pageNumber = 1, id_user, callback) => {
    return {
        type: actionTypes.FETCH_MY_TENDERS,
        token,
        searchData,
        pageNumber,
        id_user,
        callback,
    }
}
export const fetchMyTendersStart = () => {
    return {
        type: actionTypes.FETCH_MY_TENDERS_START
    }
}
export const fetchMyTendersSuccess = (myTenders, myTendersResultsCount) => {
    return {
        type: actionTypes.FETCH_MY_TENDERS_SUCCESS,
        myTenders,
        myTendersResultsCount
    }
}
export const fetchMyTendersFailed = (error) => {
    return {
        type: actionTypes.FETCH_MY_TENDERS_FAILED,
        error
    }
}

// TENDERS BOOKMARKS
export const fetchTenderBookmarks = (token, searchData, pageNumber = 1, callback) => { // SAGA
    return {
        type: actionTypes.FETCH_TENDER_BOOKMARKS,
        token,
        searchData,
        pageNumber,
        callback
    }
}
export const fetchTenderBookmarksStart = () => {
    return {
        type: actionTypes.FETCH_TENDER_BOOKMARKS_START
    }
}
export const fetchTenderBookmarksSuccess = (bookmarks, bookmarksResultsCount) => {
    return {
        type: actionTypes.FETCH_TENDER_BOOKMARKS_SUCCESS,
        bookmarks,
        bookmarksResultsCount
    }
}
export const fetchTenderBookmarksFailed = () => {
    return {
        type: actionTypes.FETCH_TENDER_BOOKMARKS_FAILED
    }
}

export const fetchTenderBookmarkIds = (token) => { // SAGA
    return {
        type: actionTypes.FETCH_TENDER_BOOKMARK_IDS,
        token,
    }
}
export const fetchTenderBookmarkIdsStart = () => {
    return {
        type: actionTypes.FETCH_TENDER_BOOKMARK_IDS_START
    }
}
export const fetchTenderBookmarkIdsSuccess = (bookmarkIds) => {
    return {
        type: actionTypes.FETCH_TENDER_BOOKMARK_IDS_SUCCESS,
        bookmarkIds,
    }
}
export const fetchTenderBookmarkIdsFailed = () => {
    return {
        type: actionTypes.FETCH_TENDER_BOOKMARK_IDS_FAILED
    }
}

export const toggleTenderBookmark = (token, tenderId, callback) => { // SAGA
    return {
        type: actionTypes.TOGGLE_TENDER_BOOKMARK,
        token,
        tenderId,
        callback
    }
}
export const toggleTenderBookmarkStart = () => {
    return {
        type: actionTypes.TOGGLE_TENDER_BOOKMARK_START
    }
}
export const toggleTenderBookmarkSuccess = (tenderId, responseMessage, callback) => {
    return {
        type: actionTypes.TOGGLE_TENDER_BOOKMARK_SUCCESS,
        tenderId,
        responseMessage,
        callback
    }
}
export const toggleTenderBookmarkFailed = () => {
    return {
        type: actionTypes.TOGGLE_TENDER_BOOKMARK_FAILED
    }
}

//TENDERS FETCH SEARCH BOOKMARKS
export const fetchTenderSearch = (token, callback) => {
    return {
        type: actionTypes.FETCH_TENDER_SEARCH,
        token,
        callback
    }
}
export const fetchTenderSearchStart = () => {
    return {
        type: actionTypes.FETCH_TENDER_SEARCH_START
    }
}
export const fetchTenderSearchSuccess = (response) => {
    return {
        type: actionTypes.FETCH_TENDER_SEARCH_SUCCESS,
        response
    }
}
export const fetchTenderSearchFailed = (error) => {
    return {
        type: actionTypes.FETCH_TENDER_SEARCH_FAILED,
        error
    }
}
