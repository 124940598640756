import {put} from 'redux-saga/effects';
import * as actionCreators from '../actions/index';
import newsAxios from '../../CustomAxios/newsAxios';
import { notify } from '../../components/UI/Notifications/Notification/Notification';

export function* searchNewsSaga(action) {
    yield put(actionCreators.searchNewsStart());
    
    const {token, searchData, pageNumber, callback} = action;
    if(!searchData.created_by)
    searchData.created_by = '';

    try {
        const response = yield newsAxios.post('/search.php', {...searchData, pageNumber}, {
            headers:{
                Authorization: token
            }
        });
        const news = yield response.data[0].data;
        const newsResultsCount = yield response.data[0].resultsCount;
        
        yield put(actionCreators.searchNewsSuccess(news, newsResultsCount));

        if(callback)
        yield callback();
    } catch (error) {
        console.log(error)
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.searchNewsFailed(error))
    }
}

export function* searchMyNewsSaga(action) {
    yield put(actionCreators.searchMyNewsStart());
    
    const {token, searchData, pageNumber, callback} = action;
    if(!searchData.created_by)
    searchData.created_by = '';

    try {
        const response = yield newsAxios.post('/search.php', {...searchData, pageNumber}, {
            headers:{
                Authorization: token
            }
        });
        const myNews = yield response.data[0].data;
        const myNewsResultsCount = yield response.data[0].resultsCount;
        
        yield put(actionCreators.searchMyNewsSuccess(myNews, myNewsResultsCount));

        if(callback)
        yield callback();
    } catch (error) {
        console.log(error)
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.searchMyNewsFailed(error))
    }
}

export function* removeNewsSaga(action) {
    try {
        yield put(actionCreators.removeNewsStart());
        
        const response = yield newsAxios.get('/delete.php?id=' + action.newsId + '&image=' + action.image,  {
            headers:{
                Authorization: action.token
            }}
        );
        console.log(response)
        response.data.success ?
        yield notify('Uklonjeno', 'Success') :
        yield notify('Stavka je već obrisana', 'Danger');

        yield put(actionCreators.removeNewsSuccess());
    } catch (error) {
        console.log(error)
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.removeNewsFailed());
    }
}