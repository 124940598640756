import React, { Component } from 'react';
import Layout from './hoc/Layout/Layout';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';

// import BookmarkedCompanies from './components/Bookmarks/Company/BookmarkedCompanies/BookmarkedCompanies';
// import AllCompanies from './containers/Company/AllCompanies/AllCompanies';
// import MyCompany from './containers/Company/MyCompany/MyCompany';
// import SingleCompany from './containers/Company/SingleCompany/SingleCompany';

import Login from './containers/Auth/Login/Login';
import Register from './containers/Auth/Register/Register';
import Logout from './containers/Auth/Logout/Logout';

import asyncComponent from './hoc/asyncComponent/AsyncComponent';
// import LoadingBar from './components/UI/LoadingBar/LoadingBar';
// import ListExample from './components/UI/Inputs/SelectKeys/SelectKeys';
// import axios from 'axios';

//company
const asyncAllCompanies = asyncComponent(() => {
  return import('./containers/Company/AllCompanies/AllCompanies')
});
const asyncBookmarkedCompanies = asyncComponent(() => {
  return import('./containers/Company/BookmarkedCompanies/BookmarkedCompanies')
});
const asyncMyCompany = asyncComponent(() => {
  return import('./containers/Company/MyCompany/MyCompany')
});
const asyncSingleCompany = asyncComponent(() => {
  return import('./containers/Company/SingleCompany/SingleCompany')
});
const asyncMyContacts = asyncComponent(() => {
  return import('./containers/Company/MyContacts/MyContacts')
});

const asyncVerify = asyncComponent(() => {
  return import('./containers/Verify/Verify')
});

//dashboard
const asyncDashBoard = asyncComponent(() => {
  return import('./containers/DashBoard/DashBoard')
});

//tender
const asyncAllTenders = asyncComponent(() => {
  return import('./containers/Tender/AllTenders/AllTenders')
});
const asyncBookmarkedTenders = asyncComponent(() => {
  return import('./containers/Tender/BookmarkedTenders/BookmarkedTenders')
});
const asyncMyTenders = asyncComponent(() => {
  return import('./containers/Tender/MyTenders/MyTenders')
});
const asyncCreateTender = asyncComponent(() => {
  return import('./containers/Tender/CreateTender/CreateTender')
});
const asyncSingleTender = asyncComponent(() => {
  return import('./containers/Tender/SingleTender/SingleTender')
});

//news
const asyncAllNews = asyncComponent(() => {
  return import('./containers/News/AllNews/AllNews')
});
const asyncMyNews = asyncComponent(() => {
  return import('./containers/News/MyNews/MyNews')
});
const asyncCreateNews = asyncComponent(() => {
  return import('./containers/News/CreateNews/CreateNews')
});
const asyncSingleNews = asyncComponent(() => {
  return import('./containers/News/SingleNews/SingleNews')
});


//blog
const asyncBlog = asyncComponent(() => {
  return import('./containers/Blog/Blog/Blog')
});
const asyncMyBlog = asyncComponent(() => {
  return import('./containers/Blog/MyBlog/MyBlog')
});
const asyncCreateBlog = asyncComponent(() => {
  return import('./containers/Blog/CreateBlog/CreateBlog')
});
const asyncSingleBlog = asyncComponent(() => {
  return import('./containers/Blog/SingleBlog/SingleBlog')
});

class App extends Component {
  componentDidMount() {
    // all important api calls
    this.props.onAuthCheckState();

    // check and set nightmode
    const nightMode = JSON.parse(localStorage.getItem('nightMode'));
    this.props.onSetNightMode(nightMode);

    window.addEventListener('storage', this.handleStorageChange);
  }
  componentWillUnmount() {
    window.removeEventListener('storage', this.handleStorageChange)
  }
  
  componentDidUpdate(prevProps) {
    if(prevProps.isAuthenticated !== this.props.isAuthenticated && !this.props.isAuthenticated)
    this.props.history.replace('/login');
  }
  
  handleStorageChange = e => {
    if (e.key === 'token' && e.oldValue && !e.newValue) {
      this.props.onLogout();
    }
    if (e.key === 'token' && !e.oldValue && e.newValue) {
      window.location.reload();
    }
    if (e.key === 'nightMode') {
      const nightMode = JSON.parse(localStorage.getItem('nightMode'));
      this.props.onSetNightMode(nightMode);
    }
  }

  render() {
    const routes = <Switch>
      <Route path='/' exact component={asyncDashBoard} />
      <Route path='/login' exact component={Login} />
      <Route path='/register' exact component={Register} />
      <Route path='/companies' exact component={asyncAllCompanies} />
      <Route path='/companies/:id' component={asyncSingleCompany} />
      <Route path='/contacts' component={asyncMyContacts} />
      <Route path='/tenders' exact component={asyncAllTenders} />
      <Route path='/create-tender' exact component={asyncCreateTender} />
      <Route path='/my-tenders' exact component={asyncMyTenders} />
      <Route path='/tenders/:id' component={asyncSingleTender} />
      <Route path='/bookmark-companies' exact component={asyncBookmarkedCompanies} />
      <Route path='/bookmark-tenders' exact component={asyncBookmarkedTenders} />
      <Route path='/news' exact component={asyncAllNews} />
      <Route path='/my-news' exact component={asyncMyNews} />
      <Route path='/create-news' exact component={asyncCreateNews} />
      <Route path='/news/:id' component={asyncSingleNews} />
      <Route path='/blog' exact component={asyncBlog} />
      <Route path='/my-blog-posts' exact component={asyncMyBlog} />
      <Route path='/add-blog-post' exact component={asyncCreateBlog} />
      <Route path='/blog/:id' component={asyncSingleBlog} />
      <Route path='/account' exact component={asyncMyCompany} />
      <Route path='/logout' exact component={Logout} />
      <Route path='/verify' exact component={asyncVerify} />
      <Redirect to='/' />
    </Switch>;

    return (
      <div>
        <Layout authed={this.props.isAuthenticated}>
          {routes}
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        categories: state.company.categories,
        isAuthenticated: state.auth.token !== null,
        nightMode: state.night.nightMode,
        token: state.auth.token,
        categoriesLoading: state.company.categoriesLoading,
        locationsLoading: state.company.locationsLoading,
        bookmarksLoading: state.company.bookmarksLoading,
    }
}
const mapDispatchToProps = dispatch => {
    return {
      onAuthCheckState: () => dispatch(actions.authCheckState()),
      onLogout: () => dispatch(actions.logout()),
      onSetNightMode: (nightMode) => dispatch(actions.setNightMode(nightMode))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
