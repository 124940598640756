import React, { useContext } from 'react';
import infobizLogo from '../../assets/images/logo.png';
import infobizLogoWhite from '../../assets/images/logo2.png';
import classes from './Logo.module.css';
import { NavLink } from 'react-router-dom';
import Context from '../../hoc/Layout/Context';
import { connect } from 'react-redux';

const Logo = (props) => {
    const context = useContext(Context);
    return (
        <NavLink 
            to='/' 
            exact 
            className={classes.Logo}
            onClick={context.hideMenu}
            // style={{backgroundImage:`url(${infobizLogo})`}}
        >
            {props.nightMode ?
            <img src={infobizLogoWhite} className={classes.NightLogoImage} alt="Infobiz"/> :
            <img src={infobizLogo} className={classes.LogoImage} alt="Infobiz"/>}
        </NavLink>
    );
};

const mapStateToProps = state => {
    return {
        nightMode: state.night.nightMode,
    }
}

export default connect(mapStateToProps, null)(Logo);