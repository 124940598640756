import axios from 'axios';
import host from '../config';

let baseURL ;
if (process.env.NODE_ENV === 'development') {
    baseURL = host + '/infobiz-app/public/api/blog/'
} else {
    baseURL =  host + '/api/blog/';
}
const blogAxios = axios.create({
    baseURL
})

export default blogAxios;