import React from 'react';
import classes from './MyProfile.module.css';
import avatar from '../../../../assets/images/profile.jpg';
import { Link } from 'react-router-dom';
import Icons from '../../../UI/Icons/MaterialIcons';
import { stringLimiter } from '../../../../shared/utility';
import host from '../../../../config';
import { Switch } from '@material-ui/core';
import * as actions from '../../../../store/actions/index';
import { connect } from 'react-redux';

const MyProfile = (props) => {
    const devImgUrl = `${host}/infobiz-app/public/images/company/${props.userId}/${props.userImage}`;
    const prodImgUrl = `${host}/images/company/${props.userId}/${props.userImage}`;
    const imageUrl = process.env.NODE_ENV === 'development' ? devImgUrl : prodImgUrl;
    
    return (
        <div className={classes.MyProfile}>
            <Link to={'/companies/' + props.userId} onClick={props.hideMenu} >
                <div className={classes.Header}>
                    <div className={classes.Avatar}>
                        {props.userImage ? 
                        <img src={imageUrl.replace(/ /gi, '%20')} alt="avatar" /> :
                        <img src={avatar} alt="avatar" />}
                    </div>
                    <div className={classes.Username}>
                        {stringLimiter(props.userName, 30)}
                    </div>
                </div>
            </Link>
            <div className={classes.Links}>
                <div>
                    {Icons.Moon} 
                    <Switch
                        checked={props.nightMode}
                        onChange={() => props.onSetNightMode(!props.nightMode)}
                        color="secondary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                </div>
                <Link onClick={props.hideMenu} to='/account'>
                    {Icons.Settings}Moj Nalog
                </Link>
                <Link onClick={props.hideMenu} to='/logout'>
                    {Icons.PowerSettingsNew}Odjava
                </Link>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        nightMode: state.night.nightMode
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onSetNightMode: (nightMode) => dispatch(actions.setNightMode(nightMode)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);