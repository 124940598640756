import { Button } from '@material-ui/core';
import React from 'react';
import Icons from '../Icons/MaterialIcons';
import classes from './ConfirmAction.module.css';

const ConfirmAction = (props) => {
    return (
        <div className={classes.ConfirmAction}>
            {props.prompt ? <h2>{props.prompt}</h2> : null}
            <div className={classes.Buttons}>
                <Button 
                    disabled={props.disabled}
                    variant="outlined" 
                    // color="primary"
                    className='myMuiConfirm'
                    onClick={props.confirm}
                >
                    {Icons.Success}Potvrdi
                </Button>
                <Button 
                    variant="outlined" 
                    color="secondary"
                    onClick={props.cancel}
                >
                    {Icons.Danger}Otkaži
                </Button>
            </div>
        </div>
    );
};

export default ConfirmAction;