import * as actionTypes from './actionTypes';

export const searchBlog = (token, searchData, pageNumber = 1, callback) => {
    return {
        type: actionTypes.SEARCH_BLOG,
        token,
        searchData,
        pageNumber,
        callback
    }
}
export const searchBlogStart = () => {
    return {
        type: actionTypes.SEARCH_BLOG_START,
    }
}
export const searchBlogSuccess = (blog, blogResultsCount) => {
    return {
        type: actionTypes.SEARCH_BLOG_SUCCESS,
        blog,
        blogResultsCount
    }
}
export const searchBlogFailed = (error) => {
    return {
        type: actionTypes.SEARCH_BLOG_FAILED,
        error
    }
}


export const searchMyBlog = (token, searchData, pageNumber = 1, callback) => {
    return {
        type: actionTypes.SEARCH_MY_BLOG,
        token,
        searchData,
        pageNumber,
        callback
    }
}
export const searchMyBlogStart = () => {
    return {
        type: actionTypes.SEARCH_MY_BLOG_START,
    }
}
export const searchMyBlogSuccess = (myBlog, myBlogResultsCount) => {
    return {
        type: actionTypes.SEARCH_MY_BLOG_SUCCESS,
        myBlog,
        myBlogResultsCount
    }
}
export const searchMyBlogFailed = (error) => {
    return {
        type: actionTypes.SEARCH_MY_BLOG_FAILED,
        error
    }
}

// REMOVE BLOG 
export const removeBlog = (token, blogId, image) => { // SAGA
    return {
        type: actionTypes.REMOVE_BLOG,
        token,
        blogId,
        image
    }
}
export const removeBlogStart = () => {
    return {
        type: actionTypes.REMOVE_BLOG_START
    }
}
export const removeBlogSuccess = () => {
    return {
        type: actionTypes.REMOVE_BLOG_SUCCESS,
    }
}
export const removeBlogFailed = () => {
    return {
        type: actionTypes.REMOVE_BLOG_FAILED
    }
}