import { notify } from '../../components/UI/Notifications/Notification/Notification';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    companiesSearchData: {
        id_cat: '',
        id_loc: '',
        search_query: ''
    },

    miscLoading: true,
    miscError: false,

    categories: [],
    locations: [],

    companies: [],
    companiesResultsCount: null,
    companiesLoading: false,
    companiesError: false,

    bookmarks: [],
    bookmarksResultsCount: null,
    bookmarksLoading: false,
    bookmarksError: false,
    
    bookmarkIds: [],
    bookmarkIdsError: false,
    toggleBookmarkLoading: false,
    toggleBookmarkError: false,
    
    companySearchBookmarks: [],
    companySearchBookmarksLoading: false,
    fetchCompanySearchError: false,

    myCompany: null,
    myCompanyLoading: false,
    fetchMyCompanyError: false,
};


export const companyInputChangedHandler = (state, action) => {
    const companiesSearchData = {...state.companiesSearchData}
    const {value, inputName} = action;
    companiesSearchData[inputName] = value;
    return {
        ...state,
        companiesSearchData
    }
}


const fetchCompanyMiscStart = (state, action) => {    
    return {
        ...state,
        miscError: false,
        miscLoading: true,
    }
}
const fetchCompanyMiscSuccess = (state, action) => {
    return {
        ...state,
        categories: action.misc.categories,
        locations: action.misc.locations,
        miscError: false,
        miscLoading: false,
    }
}
const fetchCompanyMiscFailed = (state, action) => {    
    return {
        ...state,
        miscError: action.error,
        miscLoading: false,
    }
}

const searchCompanyStart = (state, action) => {
    return {
        ...state,
        companiesError: false,
        companiesLoading: true,
        // companiesSearchData: {},
    };
}
const searchCompanySuccess = (state, action) => {
    const {
        companies, 
        companiesResultsCount, 
        companiesSearchData
    } = action;

    return {
        ...state,
        companies,
        companiesResultsCount,
        companiesSearchData,
        companiesError: false,
        companiesLoading: false,
    };
}
const searchCompanyFailed = (state, action) => {
    return {
        ...state,
        companiesError: true,
        companiesLoading: false,
    };
}

const fetchCompanyBookmarksStart = (state, action) => {    
    return {
        ...state,
        bookmarksLoading: true,
        bookmarksError: true
    }
}
const fetchCompanyBookmarksSuccess = (state, action) => {
    const {bookmarks, bookmarksResultsCount} = action;
    return {
        ...state,
        bookmarks,
        bookmarksResultsCount,
        bookmarksLoading: false,
        bookmarksError: false
    }
}
const fetchCompanyBookmarksFailed = (state, action) => {    
    return {
        ...state,
        bookmarksLoading: false,
        bookmarksError: true
    }
}


const fetchCompanyBookmarkIdsStart = (state, action) => {    
    return {
        ...state,
        bookmarkIdsError: false,
    }
}
const fetchCompanyBookmarkIdsSuccess = (state, action) => {
    const {bookmarkIds} = action;
    return {
        ...state,
        bookmarkIds,
        bookmarkIdsError: false,
    }
}
const fetchCompanyBookmarkIdsFailed = (state, action) => {    
    return {
        ...state,
        bookmarkIdsError: true,
    }
}


const toggleCompanyBookmarkStart = (state, action) => {    
    return {
        ...state,
        toggleBookmarkLoading: true,
        toggleBookmarkError: false
    }
}

const toggleCompanyBookmarkSuccess = (state, action) => {
    const {responseMessage, companyId, callback} = action;
    let bookmarkIds = [...state.bookmarkIds];
    let bookmarks = [...state.bookmarks];

    if(responseMessage === 'added' ) {
        bookmarkIds = [...bookmarkIds, companyId];
        notify('Dodato u praćenje', 'Success', callback);
    }
    if(responseMessage === 'removed' ) {
        bookmarkIds = bookmarkIds.filter(id => id !== companyId);
        bookmarks = bookmarks.filter(bm => bm.id !== companyId);
        notify('Uklonjeno iz praćenja', 'Success');
    }

    return {
        ...state,
        bookmarkIds,
        bookmarks,
        toggleBookmarkLoading: false,
        toggleBookmarkError: false
    }
}
const toggleCompanyBookmarkFailed = (state, action) => {    
    return {
        ...state,
        toggleBookmarkLoading: false,
        toggleBookmarkError: true
    }
}

const fetchCompanySearchStart = (state, action) => {
    return {
        ...state,
        companySearchBookmarksLoading: true,
        fetchCompanySearchError: false
    }
}
const fetchCompanySearchSuccess = (state, action) => {
    return {
        ...state,
        companySearchBookmarksLoading: false,
        fetchCompanySearchError: false,
        companySearchBookmarks: action.response
    }
}
const fetchCompanySearchFailed = (state, action) => {
    return {
        ...state,
        companySearchBookmarksLoading: false,
        fetchCompanySearchError: true
    }
}

const fetchMyCompanyStart = (state, action) => {
    return {
        ...state,
        myCompanyLoading: true,
        fetchMyCompanyError: false
    }
}
const fetchMyCompanySuccess = (state, action) => {
    return {
        ...state,
        myCompanyLoading: false,
        fetchMyCompanyError: false,
        myCompany: action.myCompany
    }
}
const fetchMyCompanyFailed = (state, action) => {
    return {
        ...state,
        myCompanyLoading: false,
        fetchMyCompanyError: true
    }
}




const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_STATE: return initialState;

        case actionTypes.COMPANY_INPUT_CHANGED_HANDLER: return companyInputChangedHandler(state, action);
        
        case actionTypes.FETCH_COMPANY_MISC_START: return fetchCompanyMiscStart(state, action);
        case actionTypes.FETCH_COMPANY_MISC_SUCCESS: return fetchCompanyMiscSuccess(state, action);
        case actionTypes.FETCH_COMPANY_MISC_FAILED: return fetchCompanyMiscFailed(state, action);
        
        case actionTypes.SEARCH_COMPANY_START: return searchCompanyStart(state, action);
        case actionTypes.SEARCH_COMPANY_SUCCESS: return searchCompanySuccess(state, action);
        case actionTypes.SEARCH_COMPANY_FAILED: return searchCompanyFailed(state, action);
        
        case actionTypes.FETCH_COMPANY_BOOKMARKS_START: return fetchCompanyBookmarksStart(state, action);
        case actionTypes.FETCH_COMPANY_BOOKMARKS_SUCCESS: return fetchCompanyBookmarksSuccess(state, action);
        case actionTypes.FETCH_COMPANY_BOOKMARKS_FAILED: return fetchCompanyBookmarksFailed(state, action);
        
        case actionTypes.FETCH_COMPANY_BOOKMARK_IDS_START: return fetchCompanyBookmarkIdsStart(state, action);
        case actionTypes.FETCH_COMPANY_BOOKMARK_IDS_SUCCESS: return fetchCompanyBookmarkIdsSuccess(state, action);
        case actionTypes.FETCH_COMPANY_BOOKMARK_IDS_FAILED: return fetchCompanyBookmarkIdsFailed(state, action);
        
        case actionTypes.TOGGLE_COMPANY_BOOKMARK_START: return toggleCompanyBookmarkStart(state, action);
        case actionTypes.TOGGLE_COMPANY_BOOKMARK_SUCCESS: return toggleCompanyBookmarkSuccess(state, action);
        case actionTypes.TOGGLE_COMPANY_BOOKMARK_FAILED: return toggleCompanyBookmarkFailed(state, action);
        
        case actionTypes.FETCH_COMPANY_SEARCH_START: return fetchCompanySearchStart(state, action);
        case actionTypes.FETCH_COMPANY_SEARCH_SUCCESS: return fetchCompanySearchSuccess(state, action);
        case actionTypes.FETCH_COMPANY_SEARCH_FAILED: return fetchCompanySearchFailed(state, action);
        
        case actionTypes.FETCH_MY_COMPANY_START: return fetchMyCompanyStart(state, action);
        case actionTypes.FETCH_MY_COMPANY_SUCCESS: return fetchMyCompanySuccess(state, action);
        case actionTypes.FETCH_MY_COMPANY_FAILED: return fetchMyCompanyFailed(state, action);

        default: return state;
    }
}

export default reducer;