import * as actionTypes from '../actions/actionTypes';

const initialNightMode = JSON.parse(localStorage.getItem('nightMode')) || false;

const initialState = {
    nightMode: initialNightMode,
}
export const setNightMode = (state, action) => {
    const {nightMode} = action;
    localStorage.setItem('nightMode', nightMode);
    
    nightMode ?
    document.body.classList.add('night') :
    document.body.classList.remove('night');

    return {
        ...state,
        nightMode
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NIGHT_MODE: return setNightMode(state, action);
        default: return state;
    }
}

export default reducer;