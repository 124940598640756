import * as actionTypes from './actionTypes';

export const searchNews = (token, searchData, pageNumber = 1, callback) => {
    return {
        type: actionTypes.SEARCH_NEWS,
        token,
        searchData,
        pageNumber,
        callback
    }
}
export const searchNewsStart = () => {
    return {
        type: actionTypes.SEARCH_NEWS_START,
    }
}
export const searchNewsSuccess = (news, newsResultsCount) => {
    return {
        type: actionTypes.SEARCH_NEWS_SUCCESS,
        news,
        newsResultsCount
    }
}
export const searchNewsFailed = (error) => {
    return {
        type: actionTypes.SEARCH_NEWS_FAILED,
        error
    }
}


export const searchMyNews = (token, searchData, pageNumber = 1, callback) => {
    return {
        type: actionTypes.SEARCH_MY_NEWS,
        token,
        searchData,
        pageNumber,
        callback
    }
}
export const searchMyNewsStart = () => {
    return {
        type: actionTypes.SEARCH_MY_NEWS_START,
    }
}
export const searchMyNewsSuccess = (myNews, myNewsResultsCount) => {
    return {
        type: actionTypes.SEARCH_MY_NEWS_SUCCESS,
        myNews,
        myNewsResultsCount
    }
}
export const searchMyNewsFailed = (error) => {
    return {
        type: actionTypes.SEARCH_MY_NEWS_FAILED,
        error
    }
}

// REMOVE NEWS 
export const removeNews = (token, newsId, image) => { // SAGA
    return {
        type: actionTypes.REMOVE_NEWS,
        token,
        newsId,
        image
    }
}
export const removeNewsStart = () => {
    return {
        type: actionTypes.REMOVE_NEWS_START
    }
}
export const removeNewsSuccess = () => {
    return {
        type: actionTypes.REMOVE_NEWS_SUCCESS,
    }
}
export const removeNewsFailed = () => {
    return {
        type: actionTypes.REMOVE_NEWS_FAILED
    }
}