import React, { Component } from 'react';
import { connect } from 'react-redux';
import classes from './TenderSearch.module.css';
import { withRouter } from 'react-router';
import { isObject } from '../../../shared/utility';
import { showModal } from '../../../components/UI/Modal/Modal';
import SaveSearch from '../../../components/Bookmarks/SaveSearch/SaveSearch';
import * as actions from '../../../store/actions/index';
import { notify } from '../../../components/UI/Notifications/Notification/Notification';
import MainButton from '../../../components/UI/Buttons/MainButton/MainButton';
import { Button, ButtonBase, Grid } from '@material-ui/core';
import Icons from '../../../components/UI/Icons/MaterialIcons';
import tenderAxios from '../../../CustomAxios/tenderAxios';
import BookmarkedTenderSearch from '../../../components/Bookmarks/BookmarkedTenderSearch/BookmarkedTenderSearch';
import Loader from '../../../components/UI/Loader/Loader';
import MaterialInput from '../../../components/UI/Inputs/MaterialInput/MaterialInput';

const queryString = require('query-string');

class TenderSearch extends Component {
    state = {
        searchForm: {
            keywords: {
                reduxName: 'search_query',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: '',
                },
                label: 'Ključna reč',
                validationRules: {},
                valid: true,
                touched: false,
                validationErrorMessage: '',
            },
            categories: {
                reduxName: 'id_cat',
                elementType: 'select',
                elementConfig: [],
                label: 'Kategorija',
                defaultValue: '',
                validationRules: {},
                valid: true,
                touched: true,
                validationErrorMessage: '',
            },
            sort: {
                reduxName: 'sort',
                elementType: 'select',
                elementConfig: [
                    {id: '1', name: 'Najnovije'},
                    {id: '2', name: 'Uskoro ističe'},
                ],
                label: 'Rok Isticanja',
                defaultValue: '',
                validationRules: {},
                valid: true,
                touched: true,
                validationErrorMessage: '',
            },
        },
        formIsValid: true,
    }
    
    
    componentDidMount() {
        if(isObject(this.props.categories)) {
            this.loadCategoriesIntoState()
        } else {
            this.props.onFetchTenderMisc(this.props.token, this.loadCategoriesIntoState);
        }
    }
    loadCategoriesIntoState = () => {
        const updatedSearchForm = {...this.state.searchForm};
        const updatedCategories = {...updatedSearchForm.categories}

        updatedCategories.elementConfig = this.props.categories;
        updatedSearchForm.categories = updatedCategories;
        
        this.setState({searchForm: updatedSearchForm});
    }

    inputChangedHandler = (value, inputIdentifier) => {
        this.props.onTenderInputChangedHandler(value, inputIdentifier);
    }

    searchTender = (event) => {
        event.preventDefault();
        const searchString = queryString.stringify(this.props.searchData, {
            skipEmptyString: true,
            skipNull: true
        });
        
        this.props.history.push({
            pathname: '/tenders',
            search: searchString
        });
        // window.scrollTo(0, 0);
    }
    
    saveSearch(searchData) {
        tenderAxios.post('/bookmark_search_add.php', searchData, {
            headers:{
                Authorization:this.props.token
            }
        })
        .then(res => { 
            notify('Pretraga sačuvana', 'Success');
        })
        .catch(err => {
            notify('Greška u konekciji', 'Danger');
            console.log(err)
        })
    }
    resetForm = () => {
        this.props.history.push('/tenders');
        // window.scrollTo(0, 0);
    }
    
    render() {
        const formElementsArray = [];
        for(let key in this.state.searchForm) {
            formElementsArray.push({
                id:key,
                config: this.state.searchForm[key]
            })
        }
        const form = (
            <form onSubmit={(event) => this.searchTender(event)}>
                <Grid container justify={"flex-start"} style={{boxSizing:'border-box'}}>
                    {formElementsArray.map(formElement => (
                        <Grid 
                            style={{padding: '8px 0'}}
                            item 
                            xs={12} 
                            key={formElement.id}
                        >
                            <MaterialInput 
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={this.props.searchData[formElement.config.reduxName]}
                                label={formElement.config.label}
                                changed={(event)=>this.inputChangedHandler(event.target.value, formElement.config.reduxName)}
                                onSelectChanged={(value) => this.inputChangedHandler(value, formElement.config.reduxName)}
                            />
                        </Grid>
                    ))}
                </Grid>
                <div className={classes.SubmitButtons}>
                    <MainButton 
                        btntype="submit" 
                        clicked={(event) => this.searchTender(event)}
                    >
                        Pretraži
                    </MainButton>
                    <ButtonBase
                        data-tooltip="Resetuj pretragu"
                        onClick={this.resetForm}
                        className={`${classes.ResetFormButton} tooltipBottomLeft`}
                    >
                        {Icons.Reset}
                    </ButtonBase>
                </div>
            </form>
        );

        const searchData = {...this.props.searchData}

        const saveSearch = <SaveSearch
            confirmed={(data) => this.saveSearch(data)}
            searchData={searchData} 
        />;
        const bookmarkedTenderSearch = <BookmarkedTenderSearch />;
        
        let searchBar = <>
            {form}
            <div className={classes.BookmarkSearch}>
                <Button 
                    variant="outlined" 
                    color="primary"
                    onClick={() => showModal(saveSearch)}
                >
                    {Icons.StarBorder} Sačuvaj pretragu
                </Button>
                <Button 
                    variant="outlined" 
                    color="primary"
                    onClick={() => showModal(bookmarkedTenderSearch)}
                >
                    {Icons.Star}Sačuvane pretrage
                </Button>
            </div>
        </>;

        return (
            this.props.miscLoading ? 
            <Loader /> :
            <div className={`${classes.SearchBar} scaleIn`}>
                {searchBar}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        categories: state.tender.categories,
        tenders: state.tender.tenders,
        searchData: state.tender.tendersSearchData,
        miscLoading: state.tender.miscLoading,
        isAuthenticated: state.auth.token !== null,
        token: state.auth.token,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onSearchTender: (token, searchData, tendersPageNumber, callback) => dispatch(actions.searchTender(token, searchData, tendersPageNumber, callback)),
        onFetchTenderMisc: (token, callback) => dispatch(actions.fetchTenderMisc(token, callback)),
        onTenderInputChangedHandler: (value, inputName) => dispatch(actions.tenderInputChangedHandler(value, inputName)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TenderSearch));