import React, { Component } from 'react';
import classes from './BookmarkedCompanySearch.module.css';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import Icons from '../../UI/Icons/MaterialIcons';
import companyAxios from '../../../CustomAxios/companyAxios';
import { notify } from '../../UI/Notifications/Notification/Notification';
import { withRouter } from 'react-router';
import { hideModal } from '../../UI/Modal/Modal';
// import { notify } from '../../../UI/Notifications/Notification/Notification';
import Loader from '../../UI/Loader/Loader'; 
import { hideDrawer } from '../../UI/RightDrawer/RightDrawer';

const queryString = require('query-string');

class BookmarkedCompanySearch extends Component {
    state = {
        loading: true
    }
    componentDidMount() {
        this.props.onFetchCompanySearch(this.props.token, () => this.setState({loading: false}));
    }

    openBookmarkHandler = (bookmark) => {
        const searchData = {
            id_cat: bookmark.id_cat,
            id_loc: bookmark.id_loc,
            search_query: bookmark.keywords
        }
        const searchString = queryString.stringify(searchData , {
            skipEmptyString: true,
            skipNull: true
        });
        console.log({searchString})
        this.props.history.push({
            pathname: '/companies',
            search: searchString
        });
        window.scrollTo(0, 0);
        hideModal();
        hideDrawer();
    }

    deleteBookmarkHandler = (id) => {
        companyAxios.get('/bookmark_search_delete.php?id=' + id,  {
            headers:{
                Authorization: this.props.token
            }}
        )
        .then(res => {
            notify('Uklonjeno', 'Success');
            this.props.onFetchCompanySearch(this.props.token);
        })
        .catch(err => {
            notify('Greška u konekciji', 'Danger');
            console.log(err);
        });
    }
    render() {
        let list = this.props.companySearchBookmarks.map((sb) => {
            return <div 
                className={classes.Item} 
                key={sb.id}
                onClick={() => this.openBookmarkHandler(sb)}
            >
                <div className={classes.Label}>{sb.label}</div>
                <div 
                    className={`${classes.Icon} tooltipLeft`} 
                    data-tooltip="Ukloni"
                    onClick={(event) => {
                        event.stopPropagation(); 
                        this.deleteBookmarkHandler(sb.id)
                    }}
                >
                    {Icons.Delete}
                </div>
            </div>
        });
        if(this.props.companySearchBookmarks.length < 1)
        list = <h3 className={classes.NoItems}>{Icons.Info}Nema sačuvanih pretraga</h3>;

        return (
            <div className={classes.BookmarkedCompanySearch}>
                {this.state.loading ? <Loader /> : list}
            </div> 
        );
    }
}

const mapStateToProps = state => {
    return {
        companySearchBookmarks: state.company.companySearchBookmarks,
        companySearchBookmarksLoading: state.company.companySearchBookmarksLoading,
        token: state.auth.token
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onFetchCompanySearch: (token, callback) => dispatch(actions.fetchCompanySearch(token, callback)),
        onSearchCompany: (token, searchData, pageNumber, callback) => dispatch(actions.searchCompany(token, searchData, pageNumber, callback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookmarkedCompanySearch));