import React, { Component } from 'react';
import { connect } from 'react-redux';
import classes from './CompanySearch.module.css';
import * as actions from '../../../store/actions/index';
import { notify } from '../../../components/UI/Notifications/Notification/Notification';
import MainButton from '../../../components/UI/Buttons/MainButton/MainButton';
import { Button, ButtonBase, Grid } from '@material-ui/core';
import Icons from '../../../components/UI/Icons/MaterialIcons';
import companyAxios from '../../../CustomAxios/companyAxios';
import { isObject } from '../../../shared/utility';
import { showModal } from '../../../components/UI/Modal/Modal';
import SaveSearch from '../../../components/Bookmarks/SaveSearch/SaveSearch';
import BookmarkedCompanySearch from '../../../components/Bookmarks/BookmarkedCompanySearch/BookmarkedCompanySearch';
import { withRouter } from 'react-router';
import Loader from '../../../components/UI/Loader/Loader';
import MaterialInput from '../../../components/UI/Inputs/MaterialInput/MaterialInput';

const queryString = require('query-string');

class CompanySearch extends Component {
    state = {
        searchForm: {
            keywords: {
                reduxName: 'search_query',
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: '',
                },
                label: 'Ključna reč',
                validationRules: {},
                valid: true,
                touched: true,
                validationErrorMessage: '',
            },
            locations: {
                reduxName: 'id_loc',
                elementType: 'nested-filtered-select',
                elementConfig: [],
                label: 'Lokacija',
                defaultValue: '',
                validationRules: {},
                valid: true,
                touched: true,
                validationErrorMessage: '',
            },
            categories: {
                reduxName: 'id_cat',
                elementType: 'nested-slide-select',
                elementConfig: [],
                label: 'Kategorija',
                defaultValue: '',
                validationRules: {},
                valid: true,
                touched: true,
                validationErrorMessage: '',
            }
        },
        formIsValid: true,
        showSavedSearch: false,
    }
    componentDidMount() {
        if(isObject(this.props.categories)) {
            this.loadMiscIntoState()
        } else {
            this.props.onFetchCompanyMisc(this.props.token, this.loadMiscIntoState);
        }
    }

    loadMiscIntoState = () => {
        const updatedSearchForm = {...this.state.searchForm};
        
        const updatedCategories = {...updatedSearchForm.categories}
        updatedCategories.elementConfig = this.props.categories;
        updatedSearchForm.categories = updatedCategories;
        
        const updatedLocations = {...updatedSearchForm.locations}
        updatedLocations.elementConfig = this.props.locations;
        updatedSearchForm.locations = updatedLocations;

        this.setState({searchForm: updatedSearchForm});
    }

    inputChangedHandler = (value, inputIdentifier) => {
        this.props.onCompanyInputChangedHandler(value, inputIdentifier);
    }

    searchCompany = (event) => {
        event.preventDefault();
        const searchString = queryString.stringify(this.props.searchData , {
            skipEmptyString: true,
            skipNull: true
        });
        
        this.props.history.push({
            pathname: '/companies',
            search: searchString
        });
        // window.scrollTo(0, 0);
    }
    saveSearch(searchData) {
        companyAxios.post('/bookmark_search_add.php', searchData, {
            headers:{
                Authorization:this.props.token
            }
        })
        .then(res => { 
            notify('Pretraga sačuvana', 'Success');
        })
        .catch(err => {
            notify('Greška u konekciji', 'Danger');
            console.log(err)
        })
    }
    resetForm = () => {
        this.props.history.push('/companies');
        // window.scrollTo(0, 0);
    }
    
    render() {
        const formElementsArray = [];
        for(let key in this.state.searchForm) {
            formElementsArray.push({
                id:key,
                config: this.state.searchForm[key]
            })
        }
        const form = (
            <form onSubmit={(event) => this.searchCompany(event)}>
                <Grid container  justify={"flex-start"} style={{boxSizing:'border-box'}}>
                    {formElementsArray.map(formElement => (
                        <Grid 
                            style={{padding: '8px 0'}}
                            item 
                            xs={12} 
                            key={formElement.id}
                        >
                            <MaterialInput 
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={this.props.searchData[formElement.config.reduxName]}
                                label={formElement.config.label}
                                changed={(event)=>this.inputChangedHandler(event.target.value, formElement.config.reduxName)}
                                onSelectChanged={(value) => this.inputChangedHandler(value, formElement.config.reduxName)}
                            />
                        </Grid>
                    ))}
                </Grid>
                <div className={classes.SubmitButtons}>
                    <MainButton 
                        btntype="submit" 
                        clicked={(event) => this.searchCompany(event)}
                    >
                        Pretraži
                    </MainButton>
                    
                    <ButtonBase
                        onClick={this.resetForm}
                        data-tooltip="Resetuj pretragu"
                        className={`${classes.ResetFormButton} tooltipBottomLeft`}
                    >
                        {Icons.Reset}
                    </ButtonBase>
                </div>
            </form>
        );
        
        const searchData = {...this.props.searchData};
        const saveSearch = <SaveSearch 
            confirmed={(data) => this.saveSearch(data)}
            searchData={searchData} 
        />;
        const bookmarkedCompanySearch =  <BookmarkedCompanySearch/>;

        let searchBar = <>
            {form}
            <div className={classes.BookmarkSearch}>
                <Button 
                    variant="outlined" 
                    color="primary"
                    onClick={() => showModal(saveSearch)}
                >
                    {Icons.StarBorder} Sačuvaj pretragu
                </Button>
                <Button 
                    variant="outlined" 
                    color="primary"
                    onClick={() => showModal(bookmarkedCompanySearch)}
                >
                    {Icons.Star}Sačuvane pretrage
                </Button>
            </div>
        </>;

        
        return (
            this.props.miscLoading ? 
            <Loader /> :
            <div className={`${classes.SearchBar} scaleIn`}>
                {searchBar}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        categories: state.company.categories,
        locations: state.company.locations,
        miscLoading: state.company.miscLoading,
        searchData: state.company.companiesSearchData,
        isAuthenticated: state.auth.token !== null,
        token: state.auth.token,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onFetchCompanyMisc: (token, callback) => dispatch(actions.fetchCompanyMisc(token, callback)),
        onCompanyInputChangedHandler: (value, inputName) => dispatch(actions.companyInputChangedHandler(value, inputName)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanySearch));