import React from 'react';
import classes from './MaterialInput.module.css';
// import NestedSelect from '../NestedSelect/NestedSelect';
// import Icons from '../../Icons/MaterialIcons';
// import Select from '../Select/Select';
import { 
    Grid,
    // Button, 
    TextField, 
    // withStyles 
} from '@material-ui/core';
import {
//   KeyboardDatePicker,
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import MaterialSelect from '../Select/MaterialSelect/MaterialSelect';
import MaterialGroupSelect from '../NestedSelect/MaterialGroupSelect/MaterialGroupSelect';


const Input = (props) => {
    let inputElement = null;
    
    switch (props.elementType) {
        case ('input'):
            inputElement = <TextField 
                value={props.value || ''}
                label={`${props.label} ${(props.validationRules && props.validationRules.required) ? '*' : ''}`} 
                variant="outlined" 
                onChange={props.changed}
                error={props.invalid && props.shouldValidate && props.touched}
                helperText={props.validationErrorMessage}
                spellCheck="false"
                fullWidth
                disabled={props.disabled}
                {...props.elementConfig} 
            />
            break;
        case ('date-input'):
            inputElement = <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    // required={props.validationRules && props.validationRules.dateRequired}
                    value={props.value || new Date()}
                    // label={props.label} 
                    label={`${props.label} ${(props.validationRules && props.validationRules.required) ? '*' : ''}`} 
                    onChange={props.dateChange}
                    cancelLabel='Poništi'
                    okLabel='Potvrdi'
                    color='secondary'
                    fullWidth
                />
            </MuiPickersUtilsProvider>
            break;
        case ('login-input'):
            inputElement = <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={12}>
                    <div className={classes.LoginInputContainer}>
                        <div className={classes.LoginIconContainer} style={{pointerEvents: 'none'}}>
                            {props.icon}
                        </div>
                        <TextField 
                            // label={props.label} 
                            label={`${props.label} ${(props.validationRules && props.validationRules.required) ? '*' : ''}`} 
                            value={props.value}
                            onChange={props.changed}
                            error={props.invalid && props.shouldValidate && props.touched}
                            fullWidth
                            // helperText={props.validationErrorMessage}
                            // required={props.shouldValidate && props.validationRules.required}
                            {...props.elementConfig}
                            spellCheck='false'
                            variant='outlined'
                        />
                    </div>
                </Grid>
            </Grid>;
            break;
        case ('textarea'):
            inputElement = <TextField
                value={props.value || ''}
                onChange={props.changed}
                error={props.invalid && props.shouldValidate && props.touched}
                helperText={props.validationErrorMessage}
                variant="outlined"
                // label={props.label}
                label={`${props.label} ${(props.validationRules && props.validationRules.required) ? '*' : ''}`} 
                multiline
                rowsMax={10}
                spellCheck="false"
                fullWidth
                // required={props.validationRules && props.validationRules.required}
                {...props.elementConfig} 
            />
            break;
            
        case ('select'):
            if(props.elementConfig.length > 0)
            inputElement = <MaterialSelect 
                class={classes.InputElement} 
                value={props.value}
                items={props.elementConfig} 
                itemsLength={props.elementConfig.length}
                defaultValue={props.defaultValue}
                onSelectChanged={props.onSelectChanged}
                label={props.label}
            />
            break;
        case ('nested-slide-select'):
            if(props.elementConfig.length > 0)
            // inputElement = <NestedSelect 
            //     type='slide'
            //     class={classes.InputElement} 
            //     value={props.value}
            //     items={props.elementConfig} 
            //     defaultValue={props.defaultValue}
            //     onSelectChanged={props.onSelectChanged}
            // />
            inputElement = <MaterialGroupSelect
                value={props.value}
                items={props.elementConfig} 
                label={props.label}
                onSelectChanged={props.onSelectChanged}
            />
            break;
        case ('nested-filtered-select'):
            if(props.elementConfig.length > 0)
            // inputElement = <NestedSelect 
            //     type='filtered'
            //     class={classes.InputElement} 
            //     value={props.value}
            //     items={props.elementConfig} 
            //     defaultValue={props.defaultValue}
            //     onSelectChanged={props.onSelectChanged}
            // />
            inputElement = <MaterialGroupSelect
                value={props.value}
                items={props.elementConfig} 
                label={props.label}
                onSelectChanged={props.onSelectChanged}
            />
            break;
        default:
            inputElement = <input 
                className={classes.InputElement} 
                {...props.elementConfig} 
                value={props.value}
                onChange={props.changed}
                onFocus={props.focused}
                onBlur={props.blurred}
                spellCheck="false"
            />;
    }

    return (
        <div className={classes.Input}>
            {inputElement}
        </div>
    );
};

export default React.memo(Input);