import React from 'react';
import classes from './PageTitle.module.css';
import BreadCrumbs from './BreadCrumbs/BreadCrumbs';

const PageTitle = (props) => {
    return (
        <div className={classes.PageTitle}>
            <div 
                className={classes.PageTitleOverlay}
                style={{
                    justifyContent:props.justify ? props.justify : 'space-between',
                    background: props.header ? 'var(--header-overlay-background)' : 'var(--pagetitle-overlay-background)'
                }}
            >
                {props.children}
                {props.breadCrumbs ? <BreadCrumbs items={props.breadCrumbs} /> : null}
            </div>
        </div>
    );
};

export default PageTitle;