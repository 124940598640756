import React, { Component } from 'react';
import classes from './BookmarkedTenderSearch.module.css';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import Icons from '../../UI/Icons/MaterialIcons';
import tenderAxios from '../../../CustomAxios/tenderAxios';
import { notify } from '../../UI/Notifications/Notification/Notification';
import { hideModal } from '../../UI/Modal/Modal';
import Loader from '../../UI/Loader/Loader';
import { withRouter } from 'react-router';
import { hideDrawer } from '../../UI/RightDrawer/RightDrawer';

const queryString = require('query-string');

class BookmarkedTenderSearch extends Component {
    state = {
        loading: true,
    }
    componentDidMount() {
        this.props.onFetchTenderSearch(this.props.token, () => this.setState({loading: false}));
    }

    openBookmarkHandler = (bookmark) => {
        const searchData = {
            search_query: bookmark.keywords,
            id_cat: bookmark.id_cat,
            sort: bookmark.sort,
        }
        const searchString = queryString.stringify(searchData , {
            skipEmptyString: true,
            skipNull: true
        });
        console.log({searchString})
        this.props.history.push({
            pathname: '/tenders',
            search: searchString
        });
        window.scrollTo(0, 0);
        hideModal();
        hideDrawer();
    }

    deleteBookmarkHandler = (id) => {
        tenderAxios.get('/bookmark_search_delete.php?id=' + id,  {
            headers:{
                Authorization: this.props.token
            }}
        )
        .then(res => {
            notify('Uklonjeno', 'Success');
            this.props.onFetchTenderSearch(this.props.token);
        })
        .catch(err => {
            notify('Greška u konekciji', 'Danger');
            console.log(err);
        });
    }
    render() {
        let list = this.props.tenderSearchBookmarks.map((sb) => {
            return <div 
                className={classes.Item} 
                key={sb.id}
                onClick={() => this.openBookmarkHandler(sb)}
            >
                <div className={classes.Label}>{sb.label}</div>
                <div 
                    className={`${classes.Icon} tooltipLeft`} 
                    data-tooltip="Ukloni"
                    onClick={(event) => {
                        event.stopPropagation(); 
                        this.deleteBookmarkHandler(sb.id)
                    }}
                >
                    {Icons.Delete}
                </div>
            </div>
        });
        if(this.props.tenderSearchBookmarks.length < 1)
        list = <h3 className={classes.NoItems}>{Icons.Info}Nema sačuvanih pretraga</h3>;

        return (
            <div className={classes.BookmarkedTenderSearch}>
                {this.state.loading ? <Loader /> : list}
            </div> 
        );
    }
}

const mapStateToProps = state => {
    return {
        tenderSearchBookmarksLoading: state.tender.tenderSearchBookmarksLoading,
        tenderSearchBookmarks: state.tender.tenderSearchBookmarks,
        token: state.auth.token
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onFetchTenderSearch: (token, callback) => dispatch(actions.fetchTenderSearch(token, callback)),
        onSearchTender: (token, searchData, pageNumber, callback) => dispatch(actions.searchTender(token, searchData, pageNumber, callback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookmarkedTenderSearch));