// RESET WHOLE STATE ON LOGOUT
export const RESET_STATE = 'RESET_STATE';

// set active navigation element
export const SET_ACTIVE = 'SET_ACTIVE';

// set night mode
export const SET_NIGHT_MODE = 'SET_NIGHT_MODE';

// set active multi search
export const SET_ACTIVE_SEARCH = 'SET_ACTIVE_SEARCH';

// COMPANY
export const COMPANY_INPUT_CHANGED_HANDLER = 'COMPANY_INPUT_CHANGED_HANDLER';

export const FETCH_COMPANY_MISC = 'FETCH_COMPANY_MISC';
export const FETCH_COMPANY_MISC_START = 'FETCH_COMPANY_MISC_START';
export const FETCH_COMPANY_MISC_SUCCESS = 'FETCH_COMPANY_MISC_SUCCESS';
export const FETCH_COMPANY_MISC_FAILED = 'FETCH_COMPANY_MISC_FAILED';

export const SEARCH_COMPANY = 'SEARCH_COMPANY'; // FOR SAGA
export const SEARCH_COMPANY_START = 'SEARCH_COMPANY_START';
export const SEARCH_COMPANY_SUCCESS = 'SEARCH_COMPANY_SUCCESS';
export const SEARCH_COMPANY_FAILED = 'SEARCH_COMPANY_FAILED';

export const FETCH_COMPANY_BOOKMARKS = 'FETCH_COMPANY_BOOKMARKS';
export const FETCH_COMPANY_BOOKMARKS_START = 'FETCH_COMPANY_BOOKMARKS_START';
export const FETCH_COMPANY_BOOKMARKS_SUCCESS = 'FETCH_COMPANY_BOOKMARKS_SUCCESS';
export const FETCH_COMPANY_BOOKMARKS_FAILED = 'FETCH_COMPANY_BOOKMARKS_FAILED';

export const FETCH_COMPANY_BOOKMARK_IDS = 'FETCH_COMPANY_BOOKMARK_IDS';
export const FETCH_COMPANY_BOOKMARK_IDS_START = 'FETCH_COMPANY_BOOKMARK_IDS_START';
export const FETCH_COMPANY_BOOKMARK_IDS_SUCCESS = 'FETCH_COMPANY_BOOKMARK_IDS_SUCCESS';
export const FETCH_COMPANY_BOOKMARK_IDS_FAILED = 'FETCH_COMPANY_BOOKMARK_IDS_FAILED';

export const TOGGLE_COMPANY_BOOKMARK = 'TOGGLE_COMPANY_BOOKMARK';
export const TOGGLE_COMPANY_BOOKMARK_START = 'TOGGLE_COMPANY_BOOKMARK_START';
export const TOGGLE_COMPANY_BOOKMARK_SUCCESS = 'TOGGLE_COMPANY_BOOKMARK_SUCCESS';
export const TOGGLE_COMPANY_BOOKMARK_FAILED = 'TOGGLE_COMPANY_BOOKMARK_FAILED';

export const FETCH_COMPANY_SEARCH = 'FETCH_COMPANY_SEARCH';
export const FETCH_COMPANY_SEARCH_START = 'FETCH_COMPANY_SEARCH_START';
export const FETCH_COMPANY_SEARCH_SUCCESS = 'FETCH_COMPANY_SEARCH_SUCCESS';
export const FETCH_COMPANY_SEARCH_FAILED = 'FETCH_COMPANY_SEARCH_FAILED';

export const FETCH_MY_COMPANY = 'FETCH_MY_COMPANY';
export const FETCH_MY_COMPANY_START = 'FETCH_MY_COMPANY_START';
export const FETCH_MY_COMPANY_SUCCESS = 'FETCH_MY_COMPANY_SUCCESS';
export const FETCH_MY_COMPANY_FAILED = 'FETCH_MY_COMPANY_FAILED';

// AUTH

export const SET_VERIFIED = 'SET_VERIFIED';

export const LOGIN = 'LOGIN';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const SET_LOGOUT_TIMEOUT = 'SET_LOGOUT_TIMEOUT';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const REGISTER = 'REGISTER';
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';

// TENDER
export const TENDER_INPUT_CHANGED_HANDLER = 'TENDER_INPUT_CHANGED_HANDLER';

export const FETCH_TENDER_MISC = 'FETCH_TENDER_MISC';
export const FETCH_TENDER_MISC_START = 'FETCH_TENDER_MISC_START';
export const FETCH_TENDER_MISC_SUCCESS = 'FETCH_TENDER_MISC_SUCCESS';
export const FETCH_TENDER_MISC_FAILED = 'FETCH_TENDER_MISC_FAILED';

export const SEARCH_TENDER = 'SEARCH_TENDER'; // FOR SAGA
export const SEARCH_TENDER_START = 'SEARCH_TENDER_START';
export const SEARCH_TENDER_SUCCESS = 'SEARCH_TENDER_SUCCESS';
export const SEARCH_TENDER_FAILED = 'SEARCH_TENDER_FAILED';

export const REMOVE_TENDER = 'REMOVE_TENDER';
export const REMOVE_TENDER_START = 'REMOVE_TENDER_START';
export const REMOVE_TENDER_SUCCESS = 'REMOVE_TENDER_SUCCESS';
export const REMOVE_TENDER_FAILED = 'REMOVE_TENDER_FAILED';

export const FETCH_MY_TENDERS = 'FETCH_MY_TENDERS';
export const FETCH_MY_TENDERS_START = 'FETCH_MY_TENDERS_START';
export const FETCH_MY_TENDERS_SUCCESS = 'FETCH_MY_TENDERS_SUCCESS';
export const FETCH_MY_TENDERS_FAILED = 'FETCH_MY_TENDERS_FAILED';

export const FETCH_TENDER_BOOKMARKS = 'FETCH_TENDER_BOOKMARKS';
export const FETCH_TENDER_BOOKMARKS_START = 'FETCH_TENDER_BOOKMARKS_START';
export const FETCH_TENDER_BOOKMARKS_SUCCESS = 'FETCH_TENDER_BOOKMARKS_SUCCESS';
export const FETCH_TENDER_BOOKMARKS_FAILED = 'FETCH_TENDER_BOOKMARKS_FAILED';

export const FETCH_TENDER_BOOKMARK_IDS = 'FETCH_TENDER_BOOKMARK_IDS';
export const FETCH_TENDER_BOOKMARK_IDS_START = 'FETCH_TENDER_BOOKMARK_IDS_START';
export const FETCH_TENDER_BOOKMARK_IDS_SUCCESS = 'FETCH_TENDER_BOOKMARK_IDS_SUCCESS';
export const FETCH_TENDER_BOOKMARK_IDS_FAILED = 'FETCH_TENDER_BOOKMARK_IDS_FAILED';

export const TOGGLE_TENDER_BOOKMARK = 'TOGGLE_TENDER_BOOKMARK';
export const TOGGLE_TENDER_BOOKMARK_START = 'TOGGLE_TENDER_BOOKMARK_START';
export const TOGGLE_TENDER_BOOKMARK_SUCCESS = 'TOGGLE_TENDER_BOOKMARK_SUCCESS';
export const TOGGLE_TENDER_BOOKMARK_FAILED = 'TOGGLE_TENDER_BOOKMARK_FAILED';

export const FETCH_TENDER_SEARCH = 'FETCH_TENDER_SEARCH';
export const FETCH_TENDER_SEARCH_START = 'FETCH_TENDER_SEARCH_START';
export const FETCH_TENDER_SEARCH_SUCCESS = 'FETCH_TENDER_SEARCH_SUCCESS';
export const FETCH_TENDER_SEARCH_FAILED = 'FETCH_TENDER_SEARCH_FAILED';


// NEWS
export const SEARCH_NEWS = 'SEARCH_NEWS'; // FOR SAGA
export const SEARCH_NEWS_START = 'SEARCH_NEWS_START';
export const SEARCH_NEWS_SUCCESS = 'SEARCH_NEWS_SUCCESS';
export const SEARCH_NEWS_FAILED = 'SEARCH_NEWS_FAILED';

export const SEARCH_MY_NEWS = 'SEARCH_MY_NEWS'; // FOR SAGA
export const SEARCH_MY_NEWS_START = 'SEARCH_MY_NEWS_START';
export const SEARCH_MY_NEWS_SUCCESS = 'SEARCH_MY_NEWS_SUCCESS';
export const SEARCH_MY_NEWS_FAILED = 'SEARCH_MY_NEWS_FAILED';

export const REMOVE_NEWS = 'REMOVE_NEWS';
export const REMOVE_NEWS_START = 'REMOVE_NEWS_START';
export const REMOVE_NEWS_SUCCESS = 'REMOVE_NEWS_SUCCESS';
export const REMOVE_NEWS_FAILED = 'REMOVE_NEWS_FAILED';

// BLOG
export const SEARCH_BLOG = 'SEARCH_BLOG'; // FOR SAGA
export const SEARCH_BLOG_START = 'SEARCH_BLOG_START';
export const SEARCH_BLOG_SUCCESS = 'SEARCH_BLOG_SUCCESS';
export const SEARCH_BLOG_FAILED = 'SEARCH_BLOG_FAILED';

export const SEARCH_MY_BLOG = 'SEARCH_MY_BLOG'; // FOR SAGA
export const SEARCH_MY_BLOG_START = 'SEARCH_MY_BLOG_START';
export const SEARCH_MY_BLOG_SUCCESS = 'SEARCH_MY_BLOG_SUCCESS';
export const SEARCH_MY_BLOG_FAILED = 'SEARCH_MY_BLOG_FAILED';

export const REMOVE_BLOG = 'REMOVE_BLOG';
export const REMOVE_BLOG_START = 'REMOVE_BLOG_START';
export const REMOVE_BLOG_SUCCESS = 'REMOVE_BLOG_SUCCESS';
export const REMOVE_BLOG_FAILED = 'REMOVE_BLOG_FAILED';

// DASHBOARD
export const FETCH_DASHBOARD_DATA = 'FETCH_DASHBOARD_DATA'; // FOR SAGA
export const FETCH_DASHBOARD_DATA_START = 'FETCH_DASHBOARD_DATA_START';
export const FETCH_DASHBOARD_DATA_SUCCESS = 'FETCH_DASHBOARD_DATA_SUCCESS';
export const FETCH_DASHBOARD_DATA_FAILED = 'FETCH_DASHBOARD_DATA_FAILED';