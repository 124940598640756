import * as actionTypes from '../actions/actionTypes';

const initialState = {
    navItems: [
        {
            id: 0,
            parentId: null,
            label: 'Kontrolna Tabla',
            link: '/',
            icon: 'Dashboard',
            exact: true,
            active: false,
            expand:false,
            child: false,
            children: []
        },
        {
            id: 1,
            parentId: null,
            label: 'Pratim',
            link: null,
            icon: 'Star',
            exact: false,
            active: false,
            expand:false,
            child: false,
            children: [
                {
                    id: 2,
                    parentId: 1,
                    label: 'Firme',
                    link: '/bookmark-companies',
                    exact: false,
                    active: false,
                    expand:false,
                    child: true,
                    children: []
                },
                {
                    id: 3,
                    parentId: 1,
                    label: 'Tenderi',
                    link: '/bookmark-tenders',
                    exact: false,
                    active: false,
                    expand:false,
                    child: true,
                    children: []
                }
            ]
        },
        {
            id: 4,
            parentId: null,
            label: 'Firme',
            link: null,
            icon: 'BusinessCenter',
            exact: false,
            active: false,
            expand:false,
            child: false,
            children: [
                {
                    id: 5,
                    parentId: 4,
                    label: 'Baza Firmi',
                    link: '/companies',
                    exact: false,
                    active: false,
                    expand:false,
                    child: true,
                    children: []
                },
                {
                    id: 6,
                    parentId: 4,
                    label: 'Moja Firma',
                    link: '/account',
                    exact: false,
                    active: false,
                    expand:false,
                    child: true,
                    children: []
                },
                {
                    id: 7,
                    parentId: 4,
                    label: 'Moji Kontakti',
                    link: '/contacts',
                    exact: false,
                    active: false,
                    expand:false,
                    child: true,
                    children: []
                }
            ]
        },
        {
            id: 8,
            parentId: null,
            label: 'Tenderi',
            link: null,
            icon: 'TenderSmall',
            exact: false,
            active: false,
            expand:false,
            child: false,
            children: [
                {
                    id: 9,
                    parentId: 8,
                    label: 'Svi Tenderi',
                    link: '/tenders',
                    exact: false,
                    active: false,
                    expand:false,
                    child: true,
                    children: []
                },
                {
                    id: 10,
                    parentId: 8,
                    label: 'Moji Tenderi',
                    link: '/my-tenders',
                    exact: false,
                    active: false,
                    expand:false,
                    child: true,
                    children: []
                },
                {
                    id: 11,
                    parentId: 8,
                    label: 'Dodaj Tender',
                    link: '/create-tender',
                    exact: false,
                    active: false,
                    expand:false,
                    child: true,
                    children: []
                }
            ]
        },
        {
            id: 12,
            parentId: null,
            label: 'Vesti',
            link: null,
            icon: 'NewsSmall',
            exact: false,
            active: false,
            expand:false,
            child: false,
            children: [
                {
                    id: 13,
                    parentId: 12,
                    label: 'Vesti',
                    link: '/news',
                    exact: false,
                    active: false,
                    expand:false,
                    child: true,
                    children: []
                },
                {
                    id: 14,
                    parentId: 12,
                    label: 'Moje Vesti',
                    link: '/my-news',
                    exact: false,
                    active: false,
                    expand:false,
                    child: true,
                    children: []
                },
                {
                    id: 15,
                    parentId: 12,
                    label: 'Dodaj Vest',
                    link: '/create-news',
                    exact: false,
                    active: false,
                    expand:false,
                    child: true,
                    children: []
                },
            ]
        },
        {
            id: 16,
            parentId: null,
            label: 'Blog',
            link: null,
            icon: 'Blog',
            exact: false,
            active: false,
            expand:false,
            child: false,
            children: [
                {
                    id: 17,
                    parentId: 16,
                    label: 'Blog',
                    link: '/blog',
                    exact: false,
                    active: false,
                    expand:false,
                    child: true,
                    children: []
                },
                {
                    id: 18,
                    parentId: 16,
                    label: 'Moji Blog Postovi',
                    link: '/my-blog-posts',
                    exact: false,
                    active: false,
                    expand:false,
                    child: true,
                    children: []
                },
                {
                    id: 19,
                    parentId: 16,
                    label: 'Dodaj Blog Post',
                    link: '/add-blog-post',
                    exact: false,
                    active: false,
                    expand:false,
                    child: true,
                    children: []
                }
            ]
        }
    ]
}

const setActive = (state, action) => {
    const updatedNavItems = state.navItems.map(item => {
        const updatedItem = {...item};
        updatedItem.active = false;
        if(updatedItem.id !== action.selectedItem.id) {
            updatedItem.expand = false;
        }
        return updatedItem;
    });
    
    let updatedItem;
    if(action.selectedItem.child) {
        updatedItem = updatedNavItems.find(item => item.id === action.selectedItem.parentId);
    } else {
        updatedItem = updatedNavItems.find(item => item.id === action.selectedItem.id);
    }
    updatedItem.active = true;
    updatedItem.expand = !updatedItem.expand;

    return {
        ...state,
        navItems: updatedNavItems
    }
}
const resetState = () => {
    return initialState;
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_STATE: return resetState();
        
        case actionTypes.SET_ACTIVE: return setActive(state, action);
        default: return state;
    }
}

export default reducer;