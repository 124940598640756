import React, { Component } from 'react';
import classes from './Toolbar.module.css';
import Logo from '../../Logo/Logo';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import { connect } from 'react-redux';
import Icons from '../../UI/Icons/MaterialIcons';
// import profileIcon from '../../../assets/images/profile.jpg';
import TooltipMenu from '../../UI/Tooltips/TooltipMenu/TooltipMenu';
import { ClickAwayListener } from '@material-ui/core';
import MyProfile from './MyProfile/MyProfile'
import MultiSearch from '../../../containers/MultiSearch/MultiSearch';
import { hideDrawer, showDrawer } from '../../UI/RightDrawer/RightDrawer';
// import host from '../../../config';

class Toolbar extends Component {
    state = {
        showProfile: false
    }
    toggleProfileHandler = () => {
        this.setState(prevState =>({showProfile: !prevState.showProfile}));
    }
    closeProfileHandler = () => {
        this.setState({showProfile: false});
    }

    render() {
        // const devImgUrl = `${host}/infobiz-app/public/images/company/${this.props.userId}/avatar/${this.props.userImage}`;
        // const prodImgUrl = `${host}/images/company/${this.props.userId}/avatar/${this.props.userImage}`;
        // const imageUrl = process.env.NODE_ENV === 'development' ? devImgUrl : prodImgUrl;

        const multiSearch = <MultiSearch closeModal={hideDrawer}/>;

        return (
            <header className={classes.Toolbar}>
                <div 
                    className={classes.Logo}
                    style={{justifySelf: !this.props.isAuthenticated && 'flex-end'}}
                >
                    <Logo />
                </div>
                
                {this.props.isAuthenticated ? 
                <div className={classes.NavButtons}>
                    <div className={classes.NavButtonContainer}>
                        <div 
                            className={classes.NavButtonItem}
                            onClick={() => showDrawer(multiSearch)}
                        >
                            {Icons.SearchDefault}
                        </div>
                    </div>
                    <ClickAwayListener onClickAway={this.closeProfileHandler}>
                        <div className={classes.NavButtonContainer}>
                            <div 
                                className={classes.NavButtonItem}
                                onClick={this.toggleProfileHandler}
                            >
                                {Icons.Profile}
                                {/* <div className={classes.AvatarHolder}> */}
                                    {/* {this.props.userImage ? 
                                    <img src={imageUrl.replace(/ /gi, '%20')} alt="profile" /> :
                                    <img src={profileIcon} alt="profile" />} */}
                                {/* </div> */}
                            </div>
                            <TooltipMenu 
                                width="auto" 
                                show={this.state.showProfile}
                                scaleIn
                            >
                                <MyProfile
                                    userId={this.props.userId} 
                                    hideMenu={this.closeProfileHandler} 
                                    userName={this.props.userName}
                                    userImage={this.props.userImage}
                                />
                            </TooltipMenu>
                        </div>
                    </ClickAwayListener>
                    <DrawerToggle />
                </div> : null}
            </header>
        );
    }
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        userId: state.auth.userId,
        userName: state.auth.userName,
        userImage: state.auth.userImage,
    }
}

export default connect(mapStateToProps)(Toolbar);