import React, { Component } from 'react';
import classes from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';
import { withRouter } from 'react-router';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import Icons from '../../UI/Icons/MaterialIcons';

class NavigationItems extends Component {
    componentDidMount() {
        if(
            this.props.location.pathname !== '/logout' && 
            this.props.location.pathname !== '/login' && 
            this.props.location.pathname !== '/register' &&
            this.props.location.pathname !== '/verify'
        )
        this.setActiveLink();
    }
    componentDidUpdate(prevProps) {
        if (
            (this.props.location.pathname !== prevProps.location.pathname) &&  
            (
                this.props.location.pathname !== '/logout' && 
                this.props.location.pathname !== '/login' && 
                this.props.location.pathname !== '/register' &&
                this.props.location.pathname !== '/verify'
            )
        )
        this.setActiveLink();
    }
    setActiveLink = () => {
        const allItems = this.props.navItems.reduce((acc, item) => {
            acc.push(item);

            if(item.children.length > 0)
            acc = [...acc, ...item.children]
            
            return acc;
        },[]);
        let activeItem = allItems.find(item =>  (item.link !== '/') && (this.props.location.pathname.startsWith(item.link)));
        if(this.props.location.pathname === '/')
        activeItem = allItems.find(item =>  item.link === '/');
        this.props.onSetActive(activeItem);
    }
    render() {
        const items = (this.props.navItems.map((item, i) => {
            if(item.children.length < 1) {
                return <div key={item.id}>
                    {i === 0 && <div className={classes.GroupTitle}>Moj Nalog</div>}
                    {i === 2 && <div className={classes.GroupTitle}>Firme i Tenderi</div>}
                    {i === 4 && <div className={classes.GroupTitle}>Dešavanja</div>}
                    <NavigationItem 
                        
                        link={item.link} 
                        exact={item.exact}
                        active={item.active}
                        >
                        {Icons[item.icon]} {item.label}
                    </NavigationItem>
                </div>
            } else {
                return (
                    <div key={i}>
                        {i === 0 && <div className={classes.GroupTitle}>Moj Nalog</div>}
                        {i === 2 && <div className={classes.GroupTitle}>Firme i Tenderi</div>}
                        {i === 4 && <div className={classes.GroupTitle}>Dešavanja</div>}
                        <li 
                            className={`${classes.SubmenuContainer} ${(item.active && item.expand) ? classes.Active : ''}`}
                            onClick={()=>this.props.onSetActive(item)}
                        >
                            <span 
                                className={classes.ParentLink}
                            >
                                {Icons[item.icon]} {item.label}
                            </span>
                            <ul className={`${classes.Submenu} ${item.expand ? classes.Expand : ''}`}>
                            {item.children.map(subItem => {
                                return (
                                    <NavigationItem 
                                        key={subItem.id}
                                        link={subItem.link} 
                                        exact={subItem.exact}
                                        active={subItem.active}
                                        child={subItem.child}
                                    >
                                        {subItem.label}
                                    </NavigationItem>
                                );
                            })}
                            </ul>
                        </li>
                    </div>
                )
            }
        }));
        
        return <ul className={classes.NavigationItems}>{items}</ul>;
    }
};

const mapStateToProps = state => {
    return {
        navItems: state.navigation.navItems
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onSetActive: (selectedItem) => dispatch(actions.setActive(selectedItem))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavigationItems));