import React, { useContext } from 'react';
import classes from './BreadCrumbs.module.css';
import { Link } from 'react-router-dom';
import Context from '../../../hoc/Layout/Context';
import { stringLimiter } from '../../../shared/utility';

const BreadCrumbs = (props) => {
    const context = useContext(Context)
    let breadCrumbs = null;
    if(props.items.length > 0)
    breadCrumbs = (
        props.items.map((item, i) => {
            if(!item.link)
            return (
                <li key={i} className={`${classes.BreadCrumb} ${classes.Child}`}>
                    {stringLimiter(item.caption, 25)}
                </li>
            );
            return (
                <li key={i} className={`${classes.BreadCrumb} ${classes.Child}`}>
                    <Link to={item.link}>
                        {stringLimiter(item.caption, 25)}
                    </Link>
                </li>
            );
        })
    );
    return (
        <ul className={classes.BreadCrumbs}>
            <li className={classes.BreadCrumb}>
                <Link to='/'>
                    {context.isAuthenticated ? 'Kontrolna Tabla' : 'Infobiz'}
                </Link>
            </li>
            {breadCrumbs}
        </ul>
    );
};

export default BreadCrumbs;