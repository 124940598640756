import React, { useState, useEffect, useRef } from 'react';
import classes from './SaveSearch.module.css';
// import Input from '../../UI/Inputs/Input/Input';
import { checkValidity } from '../../../shared/utility';
import ConfirmAction from '../../UI/ConfirmAction/ConfirmAction';
import { TextField } from '@material-ui/core';
import { hideModal } from '../../UI/Modal/Modal';

const SaveSearch = (props) => {
    const inputEl = useRef(null);
    const defaultInput = {
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Opis...',
            ref: inputEl
        },
        label: "Opis za ovu pretragu",
        value: '',
        validationRules: {
            required: true
        },
        valid: false,
        touched: false,
        validationErrorMessage: '',
    };
    const [input, setInput] = useState(defaultInput);
    
    useEffect(() => {
        setInput(defaultInput);
        inputEl.current.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    // console.log('rendered savesearch')
    const inputChangedHandler = (event) => {
        const updatedInput = {...input};
        const validity = checkValidity(event.target.value, updatedInput.validationRules);
        updatedInput.valid = validity.isValid;
        updatedInput.validationErrorMessage = validity.message
        updatedInput.value = event.target.value;
        updatedInput.touched = true;
        setInput(updatedInput);
    }
    const searchData = {...props.searchData};
    searchData.label =input.value;

    const submitForm = (event) => {
        event.preventDefault();
        if(input.value !== '') {
            props.confirmed(searchData);
            hideModal();
        }
    }
    return (
        <div className={classes.SaveSearch}>
            <form onSubmit={submitForm} >
                <TextField 
                    value={input.value}
                    label={input.label}
                    inputRef={inputEl}
                    variant="outlined" 
                    required
                    fullWidth
                    onChange={(event)=>inputChangedHandler(event)}
                    spellCheck='false'
                />
            </form>
            
            <ConfirmAction 
                    prompt="Sačuvaj?"
                    confirm={submitForm}
                    cancel={() => hideModal()}
                    disabled={!input.valid}
                />
        </div>
    );
};

export default React.memo(SaveSearch);