import React from 'react';
import { Button } from '@material-ui/core';
import classes from './MainButton.module.css';
import Icons from '../../Icons/MaterialIcons';

const MainButton = (props) => {
    return (
        <div className={`${classes.MainButton} ${props.withArrow ? classes.WithArrow : ''}`}>
            <Button onClick={props.clicked} type={props.btntype} disabled={props.disabled}>
                <span>{props.children}</span> {props.withArrow ? Icons.ArrowRightAlt : null}
            </Button>
        </div>
    );
};

export default MainButton;