import axios from 'axios';
import host from '../config';

let baseURL ;
if (process.env.NODE_ENV === 'development') {
    baseURL = host + '/infobiz-app/public/api/company/'
} else {
    baseURL =  host + '/api/company/';
}
const companyAxios = axios.create({
    baseURL
})

export default companyAxios;