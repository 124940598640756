import React from 'react';
import ReactDOM from 'react-dom';
import Icons from '../Icons/MaterialIcons';
import classes from './VerificationWarning.module.css';

const VerificationWarning = (props) => {
    return ReactDOM.createPortal(
        <div className={`${classes.VerificationWarning} scaleIn`}>
            <div className={classes.Content}>
                {Icons.Message} Email za verifikaciju je poslat na vašu email adresu. Ukoliko imate problem sa otvaranjem emaila kontaktirajte nas 
                <a 
                    className='hover-underline' 
                    target="_blank" rel="noopener noreferrer" 
                    href='https://www.infobiz.rs/kontakt'
                >
                    OVDE
                </a>.
            </div>
            <div className={classes.CloseIcon} onClick={props.close}>
                {Icons.Close}
            </div>
        </div>,
        document.getElementById('portal')
    );
};

export default VerificationWarning;