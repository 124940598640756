import axios from 'axios';
import host from '../config';

let baseURL ;
if (process.env.NODE_ENV === 'development') {
    baseURL = host + '/infobiz-app/public/api/auth/'
} else {
    baseURL =  host + '/api/auth/';
}
const authAxios = axios.create({
    baseURL
})

export default authAxios;