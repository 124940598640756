import axios from 'axios';
import host from '../config';

let baseURL ;
if (process.env.NODE_ENV === 'development') {
    baseURL = host + '/infobiz-app/public/api/news/'
} else {
    baseURL =  host + '/api/news/';
}
const newsAxios = axios.create({
    baseURL
})

export default newsAxios;