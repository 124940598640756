import * as actionTypes from '../actions/actionTypes';

const initialState = {
    active: 'companies',
}
export const setActiveSearch = (state, action) => {
    const {active} = action;

    return {
        ...state,
        active
    }
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ACTIVE_SEARCH: return setActiveSearch(state, action);
        default: return state;
    }
}

export default reducer;