import * as actionTypes from './actionTypes';

// set verified
export const setVerified = (verified) => {
    return {
        type: actionTypes.SET_VERIFIED,
        verified
    }
}

// LOGIN
export const login = (loginData, callback) => {
    return {
        type: actionTypes.LOGIN,
        loginData,
        callback
    };
}
export const loginStart = () => {
    return {
        type: actionTypes.LOGIN_START
    };
}
export const loginSuccess = (token, userId, userEmail, userName, userImage, verified) => {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        token,
        userId,
        userEmail,
        userName,
        userImage,
        verified
    };
}
export const loginFailed = (error) => {
    return {
        type: actionTypes.LOGIN_FAILED,
        error
    };
}

export const authCheckState = () => {
    return {
        type: actionTypes.AUTH_CHECK_STATE,
    }
}
export const setLogoutTimeout = (expirationTime) => {
    return {
        type: actionTypes.SET_LOGOUT_TIMEOUT,
        expirationTime
    }
}

export const logout = () => {
    return {
        type: actionTypes.LOGOUT
    }
}
export const logoutSuccess = () => {
    return {
        type: actionTypes.LOGOUT_SUCCESS
    }
}

// REGISTER
export const register = (registerData, callback) => {
    return {
        type: actionTypes.REGISTER,
        registerData,
        callback
    };
}
export const registerStart = () => {
    return {
        type: actionTypes.REGISTER_START
    };
}
export const registerSuccess = () => {
    return {
        type: actionTypes.REGISTER_SUCCESS,
    };
}
export const registerFailed = (error) => {
    return {
        type: actionTypes.REGISTER_FAILED,
        error
    };
}
