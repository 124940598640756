import React from 'react';
import ReactDom from 'react-dom';
import classes from './Backdrop.module.css';

const Backdrop = (props) => {
    return ReactDom.createPortal(<div 
        className={`
            ${classes.Backdrop} 
            ${props.mobileOnly ? classes.MobileOnly : ''}
            ${props.show ? classes.Show : ''}
        `}
        onClick={props.hide}
        style={{zIndex:props.zIndex ? props.zIndex : '100'}}
    />,
    document.getElementById('portal')
    );
};

export default Backdrop;