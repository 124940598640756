import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { replaceSpecialChars } from '../../../../../shared/utility';


const MaterialSelect = (props) => {
    const options =  props.items;
    
    const getOptionFromValue = (value) => {
        const item = options.find(item => item.id === value);
        return item ? item : null;
    }

    return (
        <Autocomplete
            value={getOptionFromValue(props.value)}
            onChange={(event, newValue) => {
                props.onSelectChanged(newValue ? newValue.id : '');
            }}
            options={options}
            noOptionsText='Nema rezultata'
            closeText='Zatvori'
            clearText='Poništi'
            getOptionLabel={(option) => option.name}
            filterOptions={(options, state) => options.filter(
                option => replaceSpecialChars(option.name).toLowerCase().includes(replaceSpecialChars(state.inputValue).toLowerCase())
            )}
            fullWidth
            autoComplete
            autoHighlight
            renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" />}
        />
    );
}
export default MaterialSelect;
