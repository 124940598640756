import * as actionTypes from '../actions/actionTypes';

const initialState = {
    companiesResultsCount: 0,

    tenders: [],
    tendersResultsCount: 0,

    news: [],
    newsResultsCount: 0,
    
    dashboardDataError: false,
    dashboardDataLoading: false,
};

const fetchDashboardDataStart = (state, action) => {    
    return {
        ...state,
        dashboardDataError: false,
        dashboardDataLoading: true,
    }
}
const fetchDashboardDataSuccess = (state, action) => {
    const {
        companiesResultsCount,
        tenders,
        tendersResultsCount,
        news,
        newsResultsCount,
    } = action.data;
    return {
        ...state,
        companiesResultsCount,
        tenders,
        tendersResultsCount,
        news,
        newsResultsCount,
        dashboardDataError: false,
        dashboardDataLoading: false,
    }
}
const fetchDashboardDataFailed = (state, action) => {    
    return {
        ...state,
        dashboardDataError: true,
        dashboardDataLoading: false,
    }
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.RESET_STATE: return initialState;

        case actionTypes.FETCH_DASHBOARD_DATA_START: return fetchDashboardDataStart(state, action);
        case actionTypes.FETCH_DASHBOARD_DATA_SUCCESS: return fetchDashboardDataSuccess(state, action);
        case actionTypes.FETCH_DASHBOARD_DATA_FAILED: return fetchDashboardDataFailed(state, action);
        
        default: return state;
    }
}

export default reducer;