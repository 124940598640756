import React from 'react';
import classes from './TooltipMenu.module.css';

const TooltipMenu = (props) => {
    return (
            <div 
                className={`
                    ${classes.TooltipMenu} 
                    ${props.show ? classes.Active : ''} 
                    ${props.arrow ? classes.Arrow : ''}
                    ${props.scaleIn ? classes.ScaleIn : ''}
                `}
                style={{width: props.width ? props.width : '100%'}}
            >
                {props.children}
            </div>
    );
}

export default TooltipMenu;