// import { ClickAwayListener } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ReactDom from 'react-dom';
import Icons from '../Icons/MaterialIcons';
// import Backdrop from '../Backdrop/Backdrop';
import classes from './RightDrawer.module.css';
import ee from 'event-emitter';

const emitter = new ee();
export const showDrawer = (content) => {
    emitter.emit('showdrawer', content);
}
export const hideDrawer = () => {
    emitter.emit('hidedrawer');
}

const RightDrawer = () => {
    // console.log('called')
    const [show, setShow] = useState(false);
    const [content, setContent] = useState(false);
    const [height, setHeight] = useState();
    const [loading, setLoading] = useState(false);

    const hide = () => {
        setShow(false);
        setContent(null);
    };
    
    useEffect(() => {
        emitter.on('showdrawer', (content) => {
            setShow(true);
            setContent(content);
        });
        
        emitter.on('hidedrawer', () => {
            hide();
        });
    }, []);

    useEffect(() => {
        if(show) {
            setLoading(true);
            setTimeout(() => {
                setLoading(false)            
            }, 310);

            setHeight(`${window.innerHeight}px`);
            document.body.style.overflow = 'hidden';
            
        }
        return () => {
            if(show) {
                document.body.style.overflow = 'unset';
            }
        }
    }, [show])
    
    return ReactDom.createPortal(
        <div className={classes.DrawerContainer}>
            <div 
                className={`${classes.Backdrop} ${show ? classes.Expand : ''}`}
                onClick={() => hide()}
            >
            </div>
            <div 
                style={{height}}
                className={`${classes.RightDrawer} ${show ? classes.Expand : ''}`}
            >
                <div 
                    className={classes.CloseButton}
                    onClick={() => hide()}
                >
                    {Icons.ArrowForward}{Icons.ArrowForward}{Icons.ArrowForward}
                </div>
                {loading ? null : content}
            </div>
        </div>,
        document.getElementById('portal')
    );
};

export default RightDrawer;