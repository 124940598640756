import React, { useState } from 'react';
import { withRouter } from 'react-router';
import MainButton from '../../../components/UI/Buttons/MainButton/MainButton';
import MaterialInput from '../../../components/UI/Inputs/MaterialInput/MaterialInput';
import classes from './BlogSearch.module.css';

const queryString = require('query-string');

const BlogSearch = (props) => {
    const [input, setInput] = useState('');
    const searchBlog = (event) => {
        event.preventDefault();
        const searchString = queryString.stringify({search_query: input}, {
            skipEmptyString: true,
            skipNull: true
        });
        
        props.history.push({
            pathname: '/blog',
            search: searchString
        });
    }
    
    return (
        <div className={`${classes.BlogSearch} scaleIn`}>
            <form onSubmit={(event) => searchBlog(event)}>
                <MaterialInput 
                    elementType='input'
                    elementConfig={{type: 'text'}}
                    value={input}
                    label='Ključna reč'
                    changed={event => setInput(event.target.value)}
                />
                <MainButton 
                    btntype="submit" 
                    clicked={(event) => searchBlog(event)}
                >
                    Pretraži
                </MainButton>
            </form>
        </div>
    );
};

export default withRouter(BlogSearch);