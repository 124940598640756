import {put} from 'redux-saga/effects';
import * as actionCreators from '../actions/index';
import companyAxios from '../../CustomAxios/companyAxios';
import tenderAxios from '../../CustomAxios/tenderAxios';
import newsAxios from '../../CustomAxios/newsAxios';
import { notify } from '../../components/UI/Notifications/Notification/Notification';

export function* fetchDashboardDataSaga(action) {
    try {
        yield put(actionCreators.fetchDashboardDataStart());

        const data = {};

        // companies
        const companySearchData = {
            id_cat: '',
            id_loc: '',
            search_query: '',
            pageNumber: 1
        }
        const companyResponse = yield companyAxios.post('/search.php', companySearchData, {
            headers:{
                Authorization: action.token
            }}
        );
        const companiesResultsCount = companyResponse.data[0].resultsCount;
        data.companiesResultsCount = companiesResultsCount;

        // tenders
        const tenderSearchData = {
            id_cat: '',
            sort: '',
            search_query: '',
            pageNumber: 1
        }
        const tenderResponse = yield tenderAxios.post('/search.php', tenderSearchData, {
            headers:{
                Authorization: action.token
            }}
        );
        const tendersResultsCount = tenderResponse.data[0].resultsCount;
        const tenders = tenderResponse.data[0].data.slice(0, 5);
        data.tendersResultsCount = tendersResultsCount;
        data.tenders = tenders;
        
        // news
        const newsSearchData = {
            search_query: '',
            pageNumber: 1
        }
        const newsResponse = yield newsAxios.post('/search.php', newsSearchData, {
            headers:{
                Authorization: action.token
            }}
        );
        const newsResultsCount = newsResponse.data[0].resultsCount;
        const news = newsResponse.data[0].data.slice(0, 5);
        data.newsResultsCount = newsResultsCount;
        data.news = news;

        yield put(actionCreators.fetchDashboardDataSuccess(data));

        if(action.callback)
        yield action.callback();
    }
    catch(error) {
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.fetchDashboardDataFailed(error))
    } 
};