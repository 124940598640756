import React, { Component } from 'react';
import Context from './Context';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideBar from '../../components/Navigation/SideBar/SideBar';
import classes from './Layout.module.css';
import { withRouter } from 'react-router';

import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import Notification from '../../components/UI/Notifications/Notification/Notification';
import Modal, { hideModal } from '../../components/UI/Modal/Modal';
import RightDrawer, { hideDrawer } from '../../components/UI/RightDrawer/RightDrawer';
import Footer from '../../components/Footer/Footer';
import VerificationWarning from '../../components/UI/VerificationWarning/VerificationWarning';


class Layout extends Component {
    componentDidUpdate(prevProps) {
        if(prevProps.location.pathname !== this.props.location.pathname ||
            prevProps.location.search !== this.props.location.search) {
            const callback = () => this.props.history.goForward();
            hideDrawer(callback);
            hideModal(callback);
        }
    }
    state = {
        showSideDrawer: false,
    }
    contentRef = React.createRef(null);
    
    sideDrawerClosedHandler = () => {
        this.setState({showSideDrawer: false})
    }
    sideDrawerToggleHandler = () => {
        this.setState((prevState, props) => {
            return {showSideDrawer: !prevState.showSideDrawer}
        })
    }
    render() {
        // console.log('layout called')
        return (
            <Context.Provider 
                value={{ 
                    hideMenu: this.sideDrawerClosedHandler, 
                    token: this.props.token,
                    isAuthenticated: this.props.isAuthenticated,
                    userId: this.props.userId,
                    contentRef: this.contentRef,
                    toggleTenderBookmarkLoading: this.props.toggleTenderBookmarkLoading,
                    toggleCompanyBookmarkLoading: this.props.toggleCompanyBookmarkLoading,
                    toggleMenu: this.sideDrawerToggleHandler
                }}
            >
                <Toolbar />
                {this.props.isAuthenticated ? <SideDrawer  show={this.state.showSideDrawer} hide={this.sideDrawerClosedHandler}  /> : null}
                {this.props.isAuthenticated ? <SideBar /> : null}
                <div 
                    ref={this.contentRef} 
                    className={`${classes.Content} ${!this.props.isAuthenticated ? classes.Fullwidth : ''}`}
                    style={{paddingBottom: !this.props.isAuthenticated && '0'}}
                >
                    {this.props.children}
                    {this.props.isAuthenticated ? null : <Footer />}
                </div>
                <Notification />
                <Modal />
                <RightDrawer />
                {!this.props.verified && this.props.isAuthenticated ? 
                <VerificationWarning close={() => this.props.onSetVerified(true)}/> : null}
            </Context.Provider>
        );
    }
}

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        token: state.auth.token,
        isAuthenticated:state.auth.token !== null,
        verified: state.auth.verified,
        toggleTenderBookmarkLoading: state.tender.toggleBookmarkLoading,
        toggleCompanyBookmarkLoading: state.company.toggleBookmarkLoading,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        onSetActive: (selectedItem) => dispatch(actions.setActive(selectedItem)),
        onSetVerified: (verified) => dispatch(actions.setVerified(verified)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));