import {put} from 'redux-saga/effects';
import * as actionCreators from '../actions/index';
import tenderAxios from '../../CustomAxios/tenderAxios';
import { notify } from '../../components/UI/Notifications/Notification/Notification';

export function* fetchTenderMiscSaga(action) {
    try {
        yield put(actionCreators.fetchTenderMiscStart());
        const response = yield tenderAxios.get('/read_misc.php',  {
            headers:{
                Authorization: action.token
            }}
        );
        const data = yield response.data[0].data;
        // const categories = yield data.map(cat => ({name: cat.category_name, id: cat.category_id}));
        yield put(actionCreators.fetchTenderMiscSuccess(data));

        if(action.callback)
        yield action.callback();
    }
    catch(error) {
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.fetchTenderMiscFailed(error))
    }
}

export function* searchTenderSaga(action) {
    try {
        yield put(actionCreators.searchTenderStart());

        const {searchData, pageNumber, token, callback} = action;
        const response = yield tenderAxios.post('/search.php', {...searchData, pageNumber}, {
            headers:{
                Authorization: token
            }
        });
        const tenders = yield response.data[0].data;
        const resultsCount = yield response.data[0].resultsCount;
        yield put(actionCreators.searchTenderSuccess(tenders, searchData, resultsCount, pageNumber));

        if(callback)
        yield callback();
    } catch (error) {
        console.log(error);
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.searchTenderFailed(error))
    }
}
export function* removeTenderSaga(action) {
    try {
        yield put(actionCreators.removeTenderStart());
        
        const response = yield tenderAxios.get('/delete.php?id=' + action.tenderId,  {
            headers:{
                Authorization: action.token
            }}
        );

        response.data.success ?
        yield notify('Uklonjeno', 'Success') :
        yield notify('Stavka je već obrisana', 'Danger');

        yield put(actionCreators.removeTenderSuccess(action.tenderId));
    } catch (error) {
        console.log(error)
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.removeTenderFailed());
    }
}

export function* fetchMyTendersSaga(action) {
    try {
        yield put(actionCreators.fetchMyTendersStart());

        const {token, searchData, pageNumber, id_user, callback} =  action;
        const response = yield tenderAxios.post('/search.php', {...searchData, pageNumber, id_user}, {
            headers:{
                Authorization: token
            }}
        );
        
        const myTenders = yield response.data[0].data;
        const resultsCount = yield response.data[0].resultsCount;
        yield put(actionCreators.fetchMyTendersSuccess(myTenders, resultsCount));

        if(callback)
        yield callback();
    }
    catch(error) {
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.fetchMyTendersFailed())
    }
}


export function* fetchTenderBookmarksSaga(action) {
    try {
        yield put(actionCreators.fetchTenderBookmarksStart());
        const {searchData, pageNumber, token, callback} = action;
        const response = yield tenderAxios.post('/bookmark_read_all.php', {...searchData, pageNumber}, {
            headers:{
                Authorization: token
            }}
        );
        const bookmarks = yield response.data[0].data;
        const resultsCount = yield response.data[0].resultsCount;

        yield put(actionCreators.fetchTenderBookmarksSuccess(bookmarks, resultsCount));

        if(callback)
        yield callback();
    }
    catch(error) {
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.fetchTenderBookmarksFailed())
    }
}
export function* fetchTenderBookmarkIdsSaga(action) {
    try {
        yield put(actionCreators.fetchTenderBookmarkIdsStart());

        const {token} = action;
        const response = yield tenderAxios.get('/bookmark_read_all_ids.php',  {
            headers:{
                Authorization: token
            }}
        );
        const bookmarkIds = yield response.data[0];

        yield put(actionCreators.fetchTenderBookmarkIdsSuccess(bookmarkIds));
    }
    catch(error) {
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.fetchTenderBookmarkIdsFailed())
    }
}


export function* toggleTenderBookmarkSaga(action) {
    try {
        yield put(actionCreators.toggleTenderBookmarkStart());
        
        const bookmarkData = {bookmark_id: action.tenderId};
        const response = yield tenderAxios.post('/bookmark.php', bookmarkData, {
            headers:{
                Authorization: action.token
            }
        });

        yield put(actionCreators.toggleTenderBookmarkSuccess(action.tenderId, response.data.message, action.callback));
    } catch (error) {
        console.log(error)
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.toggleTenderBookmarkFailed());
    }
}



export function* fetchTenderSearchSaga(action) {
    try {
        yield put(actionCreators.fetchTenderSearchStart());
        const response = yield tenderAxios.get('/bookmark_search_read_all.php',  {
            headers:{
                Authorization: action.token
            }}
        );
        const data = yield response.data[0].data;
        yield put(actionCreators.fetchTenderSearchSuccess(data));

        console.log(action.callback)
        if(action.callback)
        yield action.callback();
    }
    catch(error) {
        yield notify('Greška u konekciji', 'Danger');
        yield put(actionCreators.fetchTenderSearchFailed())
    }
}
