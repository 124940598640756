import React from 'react';

import Icon from '@material-ui/core/Icon';

import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswerOutlined';
import StarBorderIcon from '@material-ui/icons/StarBorderOutlined';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenterOutlined';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import LibraryAddIcon from '@material-ui/icons/LibraryAddOutlined';
import LibraryAddCheckOutlinedIcon from '@material-ui/icons/LibraryAddCheckOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAltOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOnOutlined';
import StarOutlinedIcon from '@material-ui/icons/StarOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import PhoneIphoneOutlinedIcon from '@material-ui/icons/PhoneIphoneOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';
import Brightness2OutlinedIcon from '@material-ui/icons/Brightness2Outlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

const Icons = {
    Dashboard: <DashboardIcon fontSize="small"/>,
    Add: <LibraryAddOutlinedIcon fontSize="small"/>,
    QuestionAnswer: <QuestionAnswerIcon fontSize="small"/>,
    StarBorder: <StarBorderIcon fontSize="small"/>,
    BusinessCenter: <BusinessCenterIcon fontSize="small"/>,
    Assignment: <AssignmentIcon fontSize="small"/>,
    AssignmentLarge: <AssignmentIcon fontSize="large"/>,
    NewspaperO: <Icon className="far fa-newspaper" fontSize="small"/>,
    NewspaperOLarge: <Icon className="far fa-newspaper" fontSize="large"/>,
    LibraryAdd: <LibraryAddIcon fontSize="small"/>,
    NewsSmall: <PublicOutlinedIcon fontSize="small"/>,
    NewsDefault: <PublicOutlinedIcon fontSize="default"/>,
    NewsLarge: <PublicOutlinedIcon fontSize="large"/>,
    Envelope: <Icon className="far fa-envelope" fontSize="default"/>,
    MarkAsRead: <LibraryAddCheckOutlinedIcon fontSize="small" />,
    Settings: <SettingsIcon fontSize="small" />,
    PowerSettingsNew: <PowerSettingsNewIcon fontSize="small" />,
    ArrowRightAlt: <ArrowRightAltIcon fontSize="small" />,
    LocationOn: <LocationOnIcon fontSize="small" />,
    StarDefault: <StarOutlinedIcon fontSize="default" />,
    Star: <StarOutlinedIcon fontSize="small" />,
    Save: <SaveOutlinedIcon fontSize="small" />,
    Success: <CheckOutlinedIcon fontSize="small" />,
    Danger: <CloseOutlinedIcon fontSize="small" />,
    Info: <InfoOutlinedIcon fontSize="small" />,
    Delete: <DeleteOutlinedIcon fontSize="small" />,
    Reset: <AutorenewIcon fontSize="small" />,
    ArrowBack: <ArrowBackIosOutlinedIcon fontSize="default" />,
    ArrowForward: <ArrowForwardIosOutlinedIcon fontSize="default" />,
    Message: <MailOutlineOutlinedIcon fontSize="small" />,
    Search: <SearchOutlinedIcon fontSize="small" />,
    SearchDefault: <SearchOutlinedIcon fontSize="default" />,
    SearchLarge: <SearchOutlinedIcon fontSize="large" />,
    Lock: <LockOutlinedIcon fontSize="small" />,
    Category: <LabelOutlinedIcon fontSize="small" />,
    Address: <HomeOutlinedIcon fontSize="small" />,
    Phone: <PhoneOutlinedIcon fontSize="small" />,
    Mobile: <PhoneIphoneOutlinedIcon fontSize="small" />,
    Clock: <QueryBuilderOutlinedIcon fontSize="small" />,
    Website: <LanguageOutlinedIcon fontSize="small" />,
    Date: <DateRangeOutlinedIcon fontSize="small" />,
    Close: <CancelOutlinedIcon fontSize="small" />,
    CloseDefault: <CancelOutlinedIcon fontSize="default" />,
    CloseLarge: <CancelOutlinedIcon fontSize="large" />,
    Edit: <EditOutlinedIcon fontSize="small" />,
    Contact: <PermContactCalendarOutlinedIcon fontSize="small" />,
    EditDefault: <EditOutlinedIcon fontSize="default" />,
    Attachment: <AttachFileOutlinedIcon fontSize="default" />,
    Download: <GetAppOutlinedIcon fontSize="default" />,
    TenderSmall: <GavelOutlinedIcon fontSize="small" />,
    TenderDefault: <GavelOutlinedIcon fontSize="default" />,
    TenderLarge: <GavelOutlinedIcon fontSize="large" />,
    Moon: <Brightness2OutlinedIcon fontSize="small" />,
    ArrowRight: <ArrowForwardIcon fontSize="small" />,
    Facebook: <FacebookIcon fontSize="large" />,
    Twitter: <TwitterIcon fontSize="large" />,
    Instagram: <InstagramIcon fontSize="large" />,
    Profile: <PermIdentityIcon fontSize="default" />,
    ProfileLarge: <PermIdentityIcon fontSize="large" />,
    Hash: <i className="fas fa-hashtag"></i>,
    Blog: <i className="fas fa-blog fa-lg" ></i>,
}

export default Icons;