import React from 'react';
import classes from './Loader.module.css';

const Loader = (props) => {
    return (
        <div 
            className={`${classes.Loader} ${props.button ? classes.Button : ''}`}
            style={{height: props.height}}>
            <div className={`${classes.loader} ${props.button ? classes.Button : ''}`} ></div>
        </div>
    );
};

export default Loader;