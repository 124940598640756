import React, {useContext} from 'react';
import classes from './NavigationItem.module.css';
import { NavLink } from 'react-router-dom';

import Context from '../../../../hoc/Layout/Context';

const NavigationItem = (props) => {
    const context = useContext(Context)
    return  <li 
                className={classes.NavigationItem}
            >
                <NavLink
                    className={classes.NavigationLink}
                    to={props.link}
                    exact={props.exact}
                    activeClassName={props.child ? classes.ChildActive : classes.Active}
                    onClick={(event)=>{
                        event.stopPropagation();
                        setTimeout(() => {
                            context.hideMenu();
                        }, 500)
                    }}
                >
                    {props.children}
                </NavLink>
    </li>
    }
;

export default NavigationItem;