import React, { useState } from 'react';
import classes from './Footer.module.css';
import infobizLogoWhite from '../../assets/images/logo2.png'
import { ButtonBase, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Icons from '../UI/Icons/MaterialIcons';
import { checkValidity } from '../../shared/utility';
import { notify } from '../UI/Notifications/Notification/Notification';
import newsletterAxios from '../../CustomAxios/newsletterAxios';

const Footer = (props) => {
    const [input, setInput] = useState('');
    const currentYear = new Date().getFullYear();

    const submitNewsletter = (event) => {
        event.preventDefault();

        const isValid = checkValidity(input, {email: true});
        if(!isValid.isValid)
        notify(isValid.message, 'Danger');
        
        if(isValid.isValid) {
            const email = input;
            newsletterAxios.post('/add.php',{email})
            .then(res => {
                if(res.data.success) {
                    setInput('');
                    notify(res.data.message, 'Success');
                } else {
                    notify(res.data.message, 'Info');
                }
            })
            .catch(err => {
                notify('Problem u konekciji', 'Danger');
            }) 
        }
    }
    const newsletter = <form className={classes.Newsletter} onSubmit={event => submitNewsletter(event)}>
        <input 
            value={input} 
            onChange={(event) => setInput(event.target.value)}
            type="text" 
            // name="email" 
            placeholder="Email adresa" 
            spellCheck='false'
        />
        <ButtonBase
            onClick={submitNewsletter}
        >
            {Icons.ArrowRight}
        </ButtonBase>
    </form>
    return (
        <div className={classes.Footer}>
            <div className={classes.Header}>
                <div className={classes.Logo}>
                    <img src={infobizLogoWhite} className={classes.NightLogoImage} alt="Infobiz"/>
                </div>
                <div className={classes.Social}>
                    <a
                        target="_blank" rel="noopener noreferrer" 
                        href='https://www.facebook.com/biznis.vesti.rs/'
                        className='tooltipBottom'
                        data-tooltip='Facebook'
                    >
                        {Icons.Facebook}
                    </a>
                    <a
                        target="_blank" rel="noopener noreferrer" 
                        href='https://www.instagram.com/biznis_vesti/'
                        className='tooltipBottom'
                        data-tooltip='Instagram'
                    >
                        {Icons.Instagram}
                    </a>
                    <a
                        target="_blank" rel="noopener noreferrer" 
                        href='https://twitter.com/VestiBiznis'
                        className='tooltipBottom'
                        data-tooltip='Twitter'
                    >
                        {Icons.Twitter}
                    </a>
                </div>
            </div>
            <div className={classes.Content}>
                <Grid container justify='center' className={classes.FooterItemContainer}>
                    {/* <Grid item xs={2} className={classes.FooterItem}>
                    </Grid> */}
                    <Grid item xs={6} sm={3} lg={2}  className={classes.FooterItem}>
                        <h3>Dešavanja</h3>

                        <a 
                            className='hover-underline' 
                            target="_blank" rel="noopener noreferrer" 
                            href='https://www.infobiz.rs/blog'
                        >
                            Blog
                        </a>

                        <a 
                            className='hover-underline' 
                            target="_blank" rel="noopener noreferrer" 
                            href='https://www.infobiz.rs/vesti'
                        >
                            Vesti
                        </a>
                    </Grid>
                    <Grid item xs={6} sm={3} lg={2}  className={classes.FooterItem}>
                        <h3>Za firme</h3>

                        <a 
                            className='hover-underline' 
                            target="_blank" rel="noopener noreferrer" 
                            href='https://www.infobiz.rs/baza-firmi'
                        >
                            Baza Firmi
                        </a>

                        <a 
                            className='hover-underline' 
                            target="_blank" rel="noopener noreferrer" 
                            href='https://www.infobiz.rs/tenderi'
                        >
                            Tenderi
                        </a>
                    </Grid>
                    <Grid item xs={6} sm={3} lg={2}  className={classes.FooterItem}>
                        <h3>Korisni linkovi</h3>

                        <a 
                            className='hover-underline' 
                            target="_blank" rel="noopener noreferrer" 
                            href='https://www.infobiz.rs/kontakt'
                        >
                            Kontakt
                        </a>

                        <a 
                            className='hover-underline' 
                            target="_blank" rel="noopener noreferrer" 
                            href='https://www.infobiz.rs'
                        >
                            Infobiz
                        </a>
                    </Grid>
                    <Grid item xs={6} sm={3} lg={2}  className={classes.FooterItem}>
                        <h3>Nalog</h3>
                        <Link className='hover-underline' to='/login'>Prijava</Link>
                        <Link className='hover-underline' to='/register'>Registracija</Link>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} className={classes.FooterItem}>
                        <h3>{Icons.Envelope}Prijavi se za novosti</h3>
                        <div>Nedeljni izveštaj o novim aktivnostima i dešavanjima.</div>
                        {newsletter}
                    </Grid>
                    {/* <Grid item xs={2} className={classes.FooterItem}>
                    </Grid> */}
                </Grid>
            </div>
            <div className={classes.End}>
                © {currentYear} <span>Infobiz</span>.
            </div>
        </div>
    );
};

export default Footer;